import React, { useState, useEffect, useRef } from "react";
import { useClickAway } from "react-use";
import styled from "styled-components";
import { RemoveScroll } from "react-remove-scroll";

// Popups
import { Biographies } from "../popups/biographies";
import { Archive } from "../popups/archive";
import { Conversations } from "../popups/conversations";
import { Collaborations } from "../popups/collaborations";
import { ObjectEnquiry } from "../popups/object-enquiry";
import { ObjectProcess } from "../popups/object-process";
import { AddToBag } from "../popups/add-to-bag";
import { Press } from "../popups/press";
import { Newsletter } from "../popups/newsletter";

// Icons
import { CloseToggle } from "../icons/toggle";

const Popup = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  opacity: ${(props) => props.opacity};
  background-color: ${(props) => props.background};
  transition: 250ms opacity ease, 250ms background-color ease;

  overflow-y: scroll;

  z-index: 90;

  @media (min-width: 1601px) {
    width: 1300px;
  }

  @media (max-width: 1600px) {
    width: 1100px;
  }

  @media (max-width: 1200px) {
    width: 940px;
  }

  @media (max-width: 960px) {
    width: 580px;
  }

  @media (max-width: 767px) {
    width: 75%;
  }

  & .mobile {
    display: none;

    @media (max-width: 960px) {
      display: block;

      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;

      margin: 0 15px 0 0;
    }

    @media (max-width: 600px) {
      font-size: 11px;
      line-height: 13px;
    }

    @media (max-width: 600px) {
      height: 54px;
    }
  }

  & .popup-inner-container {
    opacity: ${(props) => props.opacity};
    transition: 250ms opacity ease;
    transition-delay: 250ms;

    padding: ${(props) =>
      props.padding ? props.padding : `90px 120px 20px 120px`};
    margin: 0 0 0 auto;

    @media (max-width: 960px) {
      padding: 38px 15px 20px 15px;
      margin: 0;
    }
  }
`;

const Spacer = styled.div`
  position: sticky;
  right: 0;
  top: 0;

  width: 100%;
  height: 67px;

  opacity: ${(props) => props.opacity};
  background: ${(props) => props.background};
  // transition: 300ms opacity ease;

  z-index: 100;

  @media (max-width: 960px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    padding: 17px 15px 15px 15px;

    & .close-button {
      margin: 0 0 0 auto;
    }
  }
`;

export const DetermineActivePopup = ({
  activePopup,
  setActivePopup,
  location,
  quantity,
  setShowNewsletter,
}) => {
  const [popupOpacity, setPopupOpacity] = useState(0);
  const [isSpacerVisible, setSpacerVisible] = useState(false);

  const ref = useRef(null);
  useClickAway(ref, () => {
    if (activePopup !== null) {
      setActivePopup(null);
    }
  });

  useEffect(() => {
    if (activePopup === null) {
      setPopupOpacity(0);
    } else {
      setPopupOpacity(1);
      setSpacerVisible(false);

      const timer = setTimeout(() => {
        setSpacerVisible(true);
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [activePopup]);

  const [background, setBackground] = useState(`transparent`);

  useEffect(() => {
    if (activePopup === "press") {
      setBackground(`#EEEFEF`);
    } else if (activePopup === "biographies") {
      setBackground(`#D1BFA3`);
    } else if (activePopup === "conversations") {
      setBackground(`#d2d8da`);
    } else if (activePopup === "collaborations") {
      setBackground(`#e9e8dd`);
    } else if (activePopup === "archive") {
      setBackground(`#A2AFA0`);
    } else if (activePopup === "product-enquiry") {
      setBackground(`#d2d8da`);
    } else if (activePopup === "product-process") {
      setBackground(`#e1e4d9`);
    }
  }, [activePopup]);

  return (
    <RemoveScroll ref={ref}>
      {activePopup !== "added-to-bag" && activePopup !== "newsletter" && (
        <Popup opacity={popupOpacity} background={background}>
          <Spacer opacity={isSpacerVisible ? 1 : 0} background={background}>
            {activePopup !== "added-to-bag" && activePopup !== "newsletter" && (
              <>
                <li className="mobile menu-button">
                  <button onClick={() => setActivePopup(null)}>menu</button>
                </li>
                <li className="mobile active-popup">({activePopup})</li>
                <li className="close-button">
                  <CloseToggle onClick={() => setActivePopup(null)} />
                </li>
              </>
            )}
          </Spacer>

          <>
            {activePopup === "press" && (
              <Press
                setActivePopup={setActivePopup}
                activePopup={activePopup}
                location={location}
                quantity={quantity}
                popupOpacity={popupOpacity}
              />
            )}
            {activePopup === "biographies" && (
              <Biographies
                setActivePopup={setActivePopup}
                activePopup={activePopup}
                location={location}
                quantity={quantity}
                popupOpacity={popupOpacity}
              />
            )}
            {activePopup === "archive" && (
              <Archive
                setActivePopup={setActivePopup}
                activePopup={activePopup}
                location={location}
                quantity={quantity}
                popupOpacity={popupOpacity}
              />
            )}
            {activePopup === "conversations" && (
              <Conversations
                setActivePopup={setActivePopup}
                activePopup={activePopup}
                location={location}
                quantity={quantity}
                popupOpacity={popupOpacity}
              />
            )}
            {activePopup === "collaborations" && (
              <Collaborations
                setActivePopup={setActivePopup}
                activePopup={activePopup}
                location={location}
                quantity={quantity}
                popupOpacity={popupOpacity}
              />
            )}
            {activePopup === "product-enquiry" && (
              <ObjectEnquiry
                setActivePopup={setActivePopup}
                activePopup={activePopup}
                location={location}
                quantity={quantity}
                popupOpacity={popupOpacity}
              />
            )}
            {activePopup === "product-process" && (
              <ObjectProcess
                setActivePopup={setActivePopup}
                activePopup={activePopup}
                location={location}
                quantity={quantity}
                popupOpacity={popupOpacity}
              />
            )}

            {/* {activePopup === "added-to-bag" && (
            <AddToBag
              setActivePopup={setActivePopup}
              activePopup={activePopup}
              location={location}
              quantity={quantity}
              popupOpacity={popupOpacity}
            />
          )}

          {activePopup === "newsletter" && (
            <Newsletter
              setActivePopup={setActivePopup}
              activePopup={activePopup}
              location={location}
              quantity={quantity}
              popupOpacity={popupOpacity}
              setShowNewsletter={setShowNewsletter}
            />
          )} */}
          </>
        </Popup>
      )}

      {activePopup === "added-to-bag" && (
        <AddToBag
          setActivePopup={setActivePopup}
          activePopup={activePopup}
          location={location}
          quantity={quantity}
          popupOpacity={popupOpacity}
        />
      )}

      {activePopup === "newsletter" && (
        <Newsletter
          setActivePopup={setActivePopup}
          activePopup={activePopup}
          location={location}
          quantity={quantity}
          popupOpacity={popupOpacity}
          setShowNewsletter={setShowNewsletter}
        />
      )}
    </RemoveScroll>
  );
};
