import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import { useMedia } from "react-use";

// Components
import { PopupContainer } from "./popup-container";
import { PressGallery } from "../images/press-gallery";
import { MobilePressImages } from "../images/mobile-press-images";

const ContentContainer = styled.div`
  & h1 {
    margin: 0 0 5px 0;
  }
`;

const List = styled.ol`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;

  width: 100%;
  max-width: 640px;

  @media (max-width: 960px) {
    display: block;
    grid-template-columns: unset;
    grid-gap: unset;
  }

  & li {
    width: 100%;
    margin: 0;

    color: #000;
    cursor: pointer;

    &:hover {
      color: rgba(0, 0, 0, 0.5);
    }

    & p,
    & h2 {
      margin: 0;
      text-transform: lowercase;
    }

    & .container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;
    }

    & .read-more {
      flex: 100%;

      @media (max-width: 960px) {
        flex: 1;
      }
    }

    & .date {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;

      text-transform: lowercase;
      margin: 0 0 0 10px;

      @media (max-width: 960px) {
        line-height: 20px;
        margin: 0 10px;
      }

      @media (max-width: 600px) {
        font-size: 11px;
      }
    }

    & .title {
      font-size: 26px;
      line-height: 36px;
      letter-spacing: 0.07em;

      @media (max-width: 960px) {
        font-size: 20px;
      }

      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`;

const Text = styled.div`
  margin: 0 0 100px 0;

  @media (max-width: 600px) {
    margin: 0 0 50px 0;
  }

  & .text {
    font-size: 19px;
    line-height: 29px;
    letter-spacing: 0.02em;

    max-width: 580px;

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 960px) {
      font-size: 14px;
      line-height: 19px;

      @media (max-width: 600px) {
        font-size: 11px;
        line-height: 16px;
      }
    }
  }
`;

const ArticleContainer = styled.div`
  & .article-title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;

    & .title {
      font-size: 26px;
      line-height: 36px;
      letter-spacing: 0.07em;

      & p {
        margin: 0;
      }

      @media (max-width: 960px) {
        font-size: 20px;
      }

      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    & .date {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;

      text-transform: lowercase;
      margin: 0 0 0 10px;

      @media (max-width: 960px) {
        line-height: 20px;
      }

      @media (max-width: 600px) {
        font-size: 11px;
      }
    }
  }
`;

export const Press = ({ setActivePopup, activePopup, location, quantity }) => {
  const [isArticleOpen, setIsArticleOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const isDesktop = useMedia("(min-width: 960px)");

  const data = useStaticQuery(graphql`
    {
      prismicPress {
        data {
          text {
            html
          }
          body {
            ... on PrismicPressBodyExternalArticle {
              id
              slice_type
              primary {
                article_title {
                  html
                }
                year
              }
            }
            ... on PrismicPressBodyPdfArticle {
              id
              slice_type
              primary {
                article_title {
                  html
                }
                year
              }
            }
            ... on PrismicPressBodyArticle {
              id
              slice_type
              primary {
                article_title {
                  html
                }
                year
                link {
                  url
                }
              }
              items {
                image {
                  alt
                  fluid {
                    src
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.prismicPress.data.body.map((article, index) => {
    if (article.slice_type === "article") {
      return (
        <li
          key={`article_id_${index}}`}
          onClick={() => {
            setActiveIndex(index);
            setIsArticleOpen(true);
          }}
        >
          <div className="container">
            <div
              className="title bl-arctic-regular"
              dangerouslySetInnerHTML={{
                __html: article.primary.article_title.html,
              }}
            />
            <p className="date">{article.primary.year}</p>

            <p className="read-more">read more</p>
          </div>
        </li>
      );
    }

    if (
      article.slice_type === "external_article" ||
      article.slice_type === "pdf_article"
    ) {
      return (
        <li key={`article_id_${index}}`}>
          <div className="container">
            <div
              className="title bl-arctic-regular"
              dangerouslySetInnerHTML={{
                __html: article.primary.article_title.html,
              }}
            />

            <p className="date">{article.primary.year}</p>

            <p className="read-more">Read more</p>
          </div>
        </li>
      );
    }
  });

  return (
    <PopupContainer
      background={`#EEEFEF`}
      setActivePopup={setActivePopup}
      activePopup={activePopup}
    >
      <div className="popup-inner-container">
        {!isArticleOpen && (
          <>
            <Text>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicPress.data.text.html,
                }}
              />
            </Text>

            {content.length >= 1 && (
              <ContentContainer>
                <h1 className="small-text">press archive</h1>
                <List>{content}</List>
              </ContentContainer>
            )}
          </>
        )}

        {isArticleOpen && (
          <ArticleContainer>
            <div className="article-title">
              <div
                className="title bl-arctic-regular"
                dangerouslySetInnerHTML={{
                  __html:
                    data.prismicPress.data.body[activeIndex].primary
                      .article_title.html,
                }}
              />
              <p className="date">
                {data.prismicPress.data.body[activeIndex].primary.year}
              </p>
            </div>

            {isDesktop ? (
              <PressGallery
                images={data.prismicPress.data.body[activeIndex].items}
                setIsArticleOpen={setIsArticleOpen}
              />
            ) : (
              <MobilePressImages
                images={data.prismicPress.data.body[activeIndex].items}
              />
            )}
          </ArticleContainer>
        )}
      </div>
    </PopupContainer>
  );
};
