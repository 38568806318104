import React from "react";
import styled from "styled-components";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  margin: 75px 0 0 0;
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  margin: 0 0 30px 0;

  & img {
    max-width: 100%;

    object-fit: contain;
  }
`;

export const MobilePressImages = ({ images }) => {
  const galleryContent = images.map((content, index) => {
    return (
      <ImageContainer key={`single_press_images_${index}`}>
        {content.image.fluid !== null && (
          <img
            srcSet={content.image.fluid.srcSet}
            src={content.image.fluid.src}
            alt={content.image.alt}
            loading={
              index <= 2 || index === images.length - 1 ? `eager` : `lazy`
            }
          />
        )}
      </ImageContainer>
    );
  });

  return <GalleryContainer>{galleryContent}</GalleryContainer>;
};
