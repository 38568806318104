import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: calc(100vh - 100px - 90px - 70px);

  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .press-gallery,
  & .slider,
  & .slide,
  & video,
  & img {
    height: 100%;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  & img {
    max-width: 100%;

    object-fit: contain;

    padding: 40px 0;
  }
`;

const Caption = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  font-size: 14px;
  letter-spacing: 0.03em;
  text-transform: lowercase;

  & p {
    margin: 0;
  }

  & button {
    &:hover {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  @media (max-width: 767px) {
    & .title,
    & .project-text {
      display: none;
    }
  }
`;

const Indicators = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  & li {
    margin: 0 0 0 15px;
    cursor: pointer;

    & span {
      opacity: 0;
    }

    &.active {
      & span {
        opacity: 1;
      }
    }

    &:hover {
      & span {
        opacity: 1;
      }
    }

    @media (max-width: 767px) {
      text-align: left;

      font-size: 11px;
      line-height: 20px;

      margin: 0 20px 0 0;
    }
  }
`;

export const PressGallery = ({ images, setIsArticleOpen }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [allGalleryContent, setAllGalleryContent] = useState([]);
  const [totalSlides, setTotalSlides] = useState(null);

  useEffect(() => {
    setTotalSlides(images.length);
    setAllGalleryContent(images);
  }, []);

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const galleryContent = images.map((content, index) => {
    return (
      <ImageContainer key={`single_press_images_${index}`}>
        {content.image.fluid !== null && (
          <img
            // className={ImageOrientation(content.image)}
            srcSet={content.image.fluid.srcSet}
            src={content.image.fluid.src}
            alt={content.image.alt}
            loading={
              index <= 2 || index === images.length - 1 ? `eager` : `lazy`
            }
          />
        )}
      </ImageContainer>
    );
  });

  let imageCounter = [];
  for (let i = 0; i < totalSlides; i++) {
    imageCounter.push(
      <li
        key={i}
        onClick={() => setCurrentSlide(i)}
        className={currentSlide === i ? `active` : ``}
      >
        <span>(</span>
        {i + 1}
        <span>)</span>
      </li>
    );
  }

  return (
    <GalleryContainer>
      <Carousel
        showThumbs={false}
        autoPlay={false}
        showIndicators={false}
        showStatus={false}
        infiniteLoop={true}
        useKeyboardArrows={true}
        className="press-gallery"
        selectedItem={currentSlide}
        onChange={(index) => updateCurrentSlide(index)}
        stopOnHover={false}
        interval={0}
        transitionTime={750}
      >
        {galleryContent}
      </Carousel>

      <Caption>
        <button
          onClick={() => setIsArticleOpen(false)}
          className="project-text"
        >
          (close)
        </button>
        <Indicators>{imageCounter}</Indicators>
      </Caption>
    </GalleryContainer>
  );
};
