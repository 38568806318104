import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSessionStorage } from "react-use";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import { RemoveScroll } from "react-remove-scroll";
import isbot from "isbot";

// Context
import { Popup } from "../context/popup";
import { ActiveObject } from "../context/active-object";
import { AddedToBag } from "../context/added-to-bag";
import { ConversationsMenuColor } from "../context/conversation-menu-color";

// Components
import { Header } from "./header";
import { Footer } from "./footer";
import { Splash } from "./splash";

const Main = styled.main`
  flex: 1;
`;

export const App = ({ location, children }) => {
  const [activePopup, setActivePopup] = useState(null);

  const [activeObject, setActiveObject] = useState(null);
  const [activeBagItem, setActiveBagItem] = useState(null);
  const [menuColor, setMenuColor] = useState(`#fafaf5`);

  // Scroll to bottom of screen
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

  // Splash
  const [delay, setDelay] = useState(300);
  const [transition, setTransition] = useState(350);

  // SessionStorage
  const [isSplashOpen, setIsSplashOpen] = useSessionStorage(
    "isSplashOpen",
    true
  );
  const [isHeaderVisible, setIsHeaderVisible] = useSessionStorage(
    "isHeaderVisible",
    false
  );
  const [hideSplash, setHideSplash] = useSessionStorage("hideSplash", false);
  const [isLogoVisible, setIsLogoVisible] = useSessionStorage(
    "isLogoVisible",
    false
  );

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  useEffect(() => {
    const splashTimer = setTimeout(() => {
      setIsSplashOpen(false);

      const hideSplash = setTimeout(() => {
        setHideSplash(true);
      }, 1000);
      return () => clearTimeout(hideSplash);
    }, 10000);
    return () => clearTimeout(splashTimer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLogoVisible(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setHasScrolledToBottom(false);
  }, [location]);

  const [isUserBot, setIsUserBot] = useState(false);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsUserBot(isbot(navigator.userAgent));
    }
  }, []);

  return (
    <AddedToBag.Provider value={[activeBagItem, setActiveBagItem]}>
      <ActiveObject.Provider value={[activeObject, setActiveObject]}>
        <Popup.Provider value={[activePopup, setActivePopup]}>
          <ConversationsMenuColor.Provider value={[menuColor, setMenuColor]}>
            <>
              <Header
                location={location}
                activePopup={activePopup}
                setActivePopup={setActivePopup}
                isLogoVisible={isLogoVisible}
                delay={delay}
                transition={transition}
                isHeaderVisible={isHeaderVisible}
                setIsHeaderVisible={setIsHeaderVisible}
              />
              {/* {!hideSplash ? ( */}

              {!isUserBot && (
                <>
                  {!hideSplash && (
                    <RemoveScroll>
                      <Splash
                        opacity={isSplashOpen ? 1 : 0}
                        onClick={() => {
                          setIsHeaderVisible(true);
                          setIsLogoVisible(true);
                          setIsSplashOpen(false);
                          setHideSplash(true);
                          setDelay(0);
                          setTransition(0);
                        }}
                      />
                    </RemoveScroll>
                  )}
                </>
              )}
              {/* // ) : ( */}
              {/* // )} */}
              <>
                <Main>{children}</Main>
                {!location.includes("/collaborations/") && (
                  <Footer
                    location={location}
                    setActivePopup={setActivePopup}
                    hasScrolledToBottom={hasScrolledToBottom}
                  />
                )}
                <BottomScrollListener
                  onBottom={() => setHasScrolledToBottom(true)}
                />
              </>
              {/* // )} */}
            </>
          </ConversationsMenuColor.Provider>
        </Popup.Provider>
      </ActiveObject.Provider>
    </AddedToBag.Provider>
  );
};
