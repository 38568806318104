import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

// Components
import { DetermineActivePopup } from "./determine-active-popup";
import SearchIndex from "../search/search-index";

// Icons
import { Logo } from "../icons/logo";
import { BasketEmpty, BasketFull } from "../icons/basket";
import { OpenMenuToggle, CloseMenuToggle } from "../icons/toggle";

const HeaderWrapper = styled.div`
  position: relative;
  padding: 15px;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 15px;
  align-items: flex-start;

  background-color: ${(props) => props.bgColor};

  z-index: 200;

  & .logo-link {
    grid-column: span 4;
    z-index: 20;

    & svg {
      width: 100%;
      height: auto;

      max-width: 350px;

      @media (max-width: 600px) {
        max-width: 190px;
      }
    }
  }

  & .navigation {
    visibility: ${(props) => props.visibility};
    grid-column: span 2;
    z-index: 20;
    margin: 2px 0 0 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-end;

    & .bag-link {
      margin: 0 15px 0 0;
      height: 18px;
    }
  }
`;

const Menu = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 80;

  background-color: #e9e8dd;
  padding: 17px 15px 20px 15px;

  width: 580px;

  @media (max-width: 767px) {
    width: 75%;
  }

  & .pop-out-navigation {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;

    & .bag-link,
    & button {
      line-height: 1;
    }
  }

  & nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    // height: calc(100% - 100px - 15px - 15px);
    height: calc(100% - 85px);

    padding: 61px 0 0 0;

    & ol.header-menu {
      align-self: flex-start;

      & li {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;

        margin: 0 0 10px 0;

        @media (max-width: 600px) {
          font-size: 11px;
          line-height: 13px;

          & button {
            line-height: 14px;
          }
        }
      }
    }

    & ol.footer-menu {
      align-self: flex-end;

      & li {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;

        margin: 0 0 5px 0;

        @media (max-width: 600px) {
          font-size: 9px;
          line-height: 11px;
          letter-spacing: 0.03em;
        }
      }
    }

    & ol {
      width: 100%;

      & li {
        & button,
        & a {
          & span {
            opacity: 0;
          }

          &.current {
            & span {
              opacity: 1;
            }
          }
        }
      }
    }
  }
`;

export const MobileMenu = ({
  location,
  activePopup,
  setActivePopup,
  quantity,
  activeNavColor,
  isLogoVisible,
  isHeaderVisible,
  delay,
  transition,
  setShowNewsletter,
  menuColor,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <HeaderWrapper
      visibility={isHeaderVisible ? `visible` : `hidden`}
      // bgColor={isHeaderVisible ? `#fafaf5` : `transparent`}
      bgColor={
        isHeaderVisible
          ? location.includes(`/conversations`)
            ? menuColor
            : `#fafaf5`
          : `transparent`
      }
    >
      <div className="logo-link">
        <Link to={"/"}>
          <Logo
            isLogoVisible={isLogoVisible}
            delay={delay}
            transition={transition}
          />
        </Link>
      </div>

      {isHeaderVisible && (
        <>
          <div className="navigation">
            <div className="bag-link">
              <Link to="/bag/">
                {quantity > 0 ? <BasketFull /> : <BasketEmpty />}
              </Link>
            </div>
            <OpenMenuToggle onClick={() => setIsMenuOpen(true)} />
          </div>

          {isMenuOpen && (
            <Menu>
              <div className="pop-out-navigation">
                <div className="bag-link">
                  <Link to="/bag/">
                    {quantity > 0 ? <BasketFull /> : <BasketEmpty />}
                  </Link>
                </div>
                <CloseMenuToggle onClick={() => setIsMenuOpen(false)} />
              </div>

              <nav>
                <ol className="header-menu">
                  <li>
                    <Link
                      to="/"
                      activeClassName={activePopup === null ? "current" : ""}
                      className={
                        location.includes(`/objects/`) ? "current" : ""
                      }
                    >
                      <span>(</span>objects<span>)</span>
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={() => setActivePopup("biographies")}
                      className={activePopup === "biographies" ? "current" : ""}
                    >
                      <span>(</span>biographies<span>)</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setActivePopup("collaborations")}
                      className={
                        activePopup === "collaborations" ||
                        (location.includes(`/collaborations/`) &&
                          activePopup === null)
                          ? "current"
                          : ""
                      }
                    >
                      <span>(</span>collaborations<span>)</span>
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setActivePopup("conversations")}
                      className={
                        activePopup === "conversations" ||
                        (location.includes(`/conversations`) &&
                          activePopup === null)
                          ? "current"
                          : ""
                      }
                    >
                      <span>(</span>conversations<span>)</span>
                    </button>
                  </li>
                  <li>
                    <Link
                      to="/about"
                      activeClassName={activePopup === null ? "current" : ""}
                    >
                      <span>(</span>about<span>)</span>
                    </Link>
                  </li>

                  <li className="mobile-search">
                    <SearchIndex location={location} type={"mobile"} />
                  </li>
                </ol>

                <ol className="footer-menu">
                  <li className="small-text">
                    <Link to="/about/">
                      <span>(</span>contact<span>)</span>
                    </Link>
                  </li>
                  <li className="small-text">
                    <button
                      onClick={() => {
                        setActivePopup("newsletter");
                        setIsMenuOpen(false);
                      }}
                      className={activePopup === "newsletter" ? "current" : ""}
                    >
                      <span>(</span>newsletter<span>)</span>
                    </button>
                  </li>
                  <li className="small-text">
                    <button
                      onClick={() => setActivePopup("press")}
                      className={activePopup === "press" ? "current" : ""}
                    >
                      <span>(</span>press<span>)</span>
                    </button>
                  </li>
                  <li className="small-text">
                    <Link to={`/privacy-policy/`}>
                      <span>(</span>privacy policy<span>)</span>
                    </Link>
                  </li>
                  <li className="small-text">
                    <Link to={`/shipping-and-returns/`}>
                      <span>(</span>shipping + returns<span>)</span>
                    </Link>
                  </li>
                  <li className="small-text">
                    <Link to={`/terms-and-conditions/`}>
                      <span>(</span>terms + conditions<span>)</span>
                    </Link>
                  </li>
                </ol>
              </nav>
            </Menu>
          )}
        </>
      )}

      {activePopup !== null && (
        <DetermineActivePopup
          activePopup={activePopup}
          setActivePopup={setActivePopup}
          setShowNewsletter={setShowNewsletter}
        />
      )}
    </HeaderWrapper>
  );
};
