import React from "react";
import styled from "styled-components";

const Icon = styled.svg``;

export const SearchIcon = () => (
  <Icon
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8535 13.0442L18.0104 18.3033M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8Z"
      stroke="black"
    />
  </Icon>
);
