import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import Media from "react-media";
import { useStaticQuery, graphql } from "gatsby";
// import { useSessionStorage } from "react-use";
import { useSessionStorageBoolean } from "react-use-window-sessionstorage";

// Context
import StoreContext from "../context/store";
import { ConversationsMenuColor } from "../context/conversation-menu-color";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

const countQuantity = (lineItems) => {
  let quantity = 0;

  lineItems.forEach((item) => {
    quantity = quantity + item.quantity;
  });
  return quantity;
};

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.background};

  z-index: 20000;
`;

export const Header = ({
  location,
  activePopup,
  setActivePopup,
  isLogoVisible,
  delay,
  transition,
  isHeaderVisible,
  setIsHeaderVisible,
}) => {
  const [windowWidth, setWindowWidth] = useState(767);
  const [activeNavColor, setActiveNavColor] = useState(`transparent`);

  const [menuColor, setMenuColor] = useContext(ConversationsMenuColor);

  const context = useContext(StoreContext);
  const { checkout } = context.store;
  const [quantity, setQuantity] = useState(
    countQuantity(checkout ? checkout.lineItems : [])
  );

  useEffect(() => {
    setQuantity(countQuantity(checkout ? checkout.lineItems : []));
  }, [checkout]);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let getWindowWidth = window.innerWidth;
      setWindowWidth(getWindowWidth);
    }
  }, []);

  useEffect(() => {
    setActivePopup(null);
  }, [location]);

  const data = useStaticQuery(graphql`
    {
      prismicNewsletter {
        data {
          display_newsletter_popup
        }
      }
    }
  `);

  const [isNewsletterOpen, setIsNewsletterOpen] = useState(false);
  const [showNewsletter, setShowNewsletter] = useSessionStorageBoolean(
    "showNewsletter",
    true
  );

  useEffect(() => {
    setIsNewsletterOpen(data.prismicNewsletter.data.display_newsletter_popup);
  }, [data]);

  useEffect(() => {
    const visibleHeaderTimer = setTimeout(() => {
      setIsHeaderVisible(true);
    }, 10000);
    return () => clearTimeout(visibleHeaderTimer);
  }, []);

  useEffect(() => {
    if (isNewsletterOpen && showNewsletter) {
      const visibleNewsletter = setTimeout(() => {
        setActivePopup(`newsletter`);
      }, 60000);
      return () => clearTimeout(visibleNewsletter);
    }
  }, [isNewsletterOpen]);

  return (
    <HeaderWrapper
      background={isHeaderVisible ? activeNavColor : `transparent`}
    >
      <Media
        queries={{
          medium: "(max-width: 960px)",
        }}
        defaultMatches={{
          medium: windowWidth === 960,
        }}
        render={() => (
          <MobileMenu
            location={location}
            context={context}
            checkout={checkout}
            activePopup={activePopup}
            setActivePopup={setActivePopup}
            quantity={quantity}
            activeNavColor={activeNavColor}
            setActiveNavColor={setActiveNavColor}
            isLogoVisible={isLogoVisible}
            isHeaderVisible={isHeaderVisible}
            delay={delay}
            transition={transition}
            setShowNewsletter={setShowNewsletter}
            menuColor={menuColor}
          />
        )}
      />

      <Media
        queries={{
          medium: "(min-width: 961px)",
        }}
        defaultMatches={{
          medium: windowWidth === 961,
        }}
        render={() => (
          <DesktopMenu
            location={location}
            context={context}
            checkout={checkout}
            activePopup={activePopup}
            setActivePopup={setActivePopup}
            quantity={quantity}
            activeNavColor={activeNavColor}
            setActiveNavColor={setActiveNavColor}
            isLogoVisible={isLogoVisible}
            isHeaderVisible={isHeaderVisible}
            delay={delay}
            transition={transition}
            setShowNewsletter={setShowNewsletter}
            menuColor={menuColor}
          />
        )}
      />
    </HeaderWrapper>
  );
};
