import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useFlexSearch } from "react-use-flexsearch";
import { navigate } from "gatsby";

// Icons
import { SearchIcon } from "../icons/search-icon";

const SearchContainer = styled.div`
  grid-column: 1 / 5;

  width: 100%;
  padding: 0 0 0 20px;

  @media (max-width: 1030px) {
    grid-column: 1 / 4;
  }

  & button {
    margin: 0;
    cursor: pointer;
    width: 19px;
    height: 19px;
  }

  @media (max-width: 960px) {
    width: 100%;
    max-width: 100%;

    margin: 0;
    padding: 0;

    & button {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      line-height: 50px;
    }
  }
`;

const SearchForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;

  width: 100%;
  max-width: 380px;

  @media (max-width: 500px) {
    max-width: 100%;
  }

  & input {
    padding: 0;
    margin: 0 20px 0 0;

    border: 0;

    width: calc(100% - 40px);
    border-bottom: 1px solid #000;

    background-color: transparent;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.03em;
  }

  & button[type="submit"] {
    margin: 0;
  }

  & button {
    margin: 0;
    cursor: pointer;
    width: 19px;
    height: 19px;
  }
`;

export const Search = ({
  index,
  store,
  location,
  isSearchOpen,
  setSearchOpen,
}) => {
  const [query, setQuery] = useState("");
  const results = useFlexSearch(query, index, store);

  const navigateToSearchResultsPage = (event) => {
    event.preventDefault();
    setSearchOpen(false);
    navigate(`/search/?q=${query}`, {
      state: {
        searchResults: results,
        searchQuery: query,
      },
    });
  };

  useEffect(() => {
    if (typeof window !== undefined && isSearchOpen === true) {
      document.getElementById("search-input").focus();
    }
  }, [isSearchOpen]);

  useEffect(() => {
    setSearchOpen(false);
    setQuery("");
  }, [location]);

  return (
    <SearchContainer>
      {isSearchOpen ? (
        <SearchForm onSubmit={navigateToSearchResultsPage}>
          <input
            id="search-input"
            name="query"
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />

          <button type="submit">
            <SearchIcon />
          </button>
        </SearchForm>
      ) : (
        <button
          onClick={() => setSearchOpen(true)}
          className={isSearchOpen ? `is-open` : `is-closed`}
        >
          <SearchIcon />
        </button>
      )}
    </SearchContainer>
  );
};

// export default Search;
