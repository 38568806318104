import React from "react";

// Components
import { Search } from "./search";
import { MobileSearch } from "./mobile-search";
import { SearchPageSearch } from "./search-page-search";

// Hooks
import { UseSearchData } from "../hooks/useSearchData";

const SearchIndex = ({ location, type, isSearchOpen, setSearchOpen }) => {
  const data = UseSearchData();

  if (data !== null) {
    if (type === "header") {
      return (
        <Search
          index={data.index}
          store={data.store}
          location={location}
          isSearchOpen={isSearchOpen}
          setSearchOpen={setSearchOpen}
        />
      );
    } else if (type === "mobile") {
      return (
        <MobileSearch
          index={data.index}
          store={data.store}
          location={location}
        />
      );
    } else if (type === "search-page") {
      return (
        <SearchPageSearch
          index={data.index}
          store={data.store}
          location={location}
        />
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default SearchIndex;
