import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

// Components
import { PopupContainer } from "./popup-container";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Utils
import { FormatProductTitle } from "../utils/format-product-title";

const List = styled.ol`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & li {
    width: 100%;
    margin: 0 0 180px 0;

    @media (max-width: 960px) {
      margin: 0 0 60px 0;
    }

    & .container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      & .image-container {
        width: 100%;
        max-width: 255px;

        @media (max-width: 1200px) {
          max-width: 185px;
        }

        // @media (max-width: 600px) {
        //   max-width: 95px;
        // }

        & .content {
          mix-blend-mode: multiply;
          background: #d1bfa3;
        }

        & img {
          object-fit: cover;
          width: 100%;
          height: 100%;

          mix-blend-mode: multiply;
          filter: grayscale(1);
        }
      }

      & .details {
        flex: 1;
        padding: 0 0 0 100px;
        max-width: 680px;

        @media (max-width: 1200px) {
          padding: 0 0 0 50px;
        }

        @media (max-width: 960px) {
          flex: 100%;
          padding: 20px 0 0 0;
        }

        & .name {
          font-size: 26px;
          line-height: 30px;
          letter-spacing: 0.07em;

          text-transform: lowercase;

          margin: 0 0 1em 0;

          @media (max-width: 960px) {
            font-size: 20px;
          }

          @media (max-width: 600px) {
            font-size: 14px;
            line-height: 20px;
          }
        }

        & .text {
          font-size: 19px;
          line-height: 29px;
          letter-spacing: 0.02em;

          & a {
            &::before {
              content: "(";
            }
            &::after {
              content: ")";
            }
          }

          & p:last-of-type {
            margin-bottom: 0;
          }

          @media (max-width: 1300px) {
            font-size: 16px;
            line-height: 22px;
          }

          @media (max-width: 960px) {
            font-size: 14px;
            line-height: 19px;
          }

          @media (max-width: 600px) {
            font-size: 11px;
            line-height: 16px;
          }
        }

        & .maker-objects-container {
          margin: 20px 0 0 0;

          & .single-object {
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.02em;

            margin: 0;

            & .object-title {
              text-transform: lowercase;
            }

            @media (max-width: 600px) {
              font-size: 9px;
              line-height: 12px;
            }
          }
        }
      }
    }
  }
`;

const Filters = styled.div`
  position: relative;
  width: 100%;
  // padding: 0 0 35px 97px;
  padding: 0 0 35px 0;

  @media (max-width: 600px) {
    padding: 0 0 15px 0;
  }

  & .filter-container {
    grid-column: 1/7;

    & .filters-right {
      display: flex;
      flex-direciton: row;
      flex-wrap: wrap;

      & .clear-filter-button {
        margin: 0 30px 0 0;
      }
    }
  }

  & .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    & button {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.03em;

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 11px;
      }

      margin: 0;

      &.button-right {
        margin: 0 0 0 auto;

        @media (max-width: 960px) {
          margin: 0;
        }
      }

      &.with-marker {
        & svg {
          margin: 0 7px -2px 0;
        }
      }
    }
  }

  & .filter-buttons-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;

    z-index: 100;
    margin: 1em 0 0 -6px;

    @media (max-width: 600px) {
      margin: 1em 0 0 -3px;
    }

    & .top-line {
      margin: 0 0 30px 0;
    }

    & .grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 20px;
      align-items: flex-start;

      grid-column: 1/7;
    }

    & button {
      text-align: left;

      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.03em;

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 16px;
      }

      display: block;

      & span {
        opacity: 0;
      }

      &.active {
        & span {
          opacity: 1;
        }
      }

      &:hover {
        & span {
          opacity: 1;
        }
      }
    }

    & .filter-options,
    & .close-button {
      grid-column: 3/7;
    }

    & .filter-options {
      & button {
        text-transform: lowercase;
      }
    }

    & .inner-filters-container {
      grid-column: 3/7;
      min-height: 200px;

      & button {
        display: block;
      }
    }
  }
`;

export const Biographies = ({
  setActivePopup,
  activePopup,
  location,
  quantity,
  popupOpacity,
}) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const [activeMaterial, setActiveMaterial] = useState("All");
  const [activeMaker, setActiveMaker] = useState("All");

  const [openFilter, setOpenFilter] = useState(null);

  const data = useStaticQuery(graphql`
    {
      prismicBiographies {
        data {
          makers {
            maker {
              document {
                ... on PrismicMaker {
                  id
                  data {
                    name {
                      text
                    }
                    biography {
                      html
                    }
                    thumbnail {
                      alt
                      fluid {
                        src
                        srcSet
                      }
                    }
                    objects {
                      object {
                        document {
                          ... on PrismicObject {
                            id
                            url
                            data {
                              title {
                                text
                              }
                              year
                              shopify_product
                              filter_materials {
                                single_material
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.prismicBiographies.data.makers
    .filter((maker) => maker.maker.document !== null)
    .filter((maker) => {
      if (activeMaterial === "All") {
        return maker;
      } else {
        return maker.maker.document.data.objects
          .filter((object) => object.object.document !== null)
          .map((object) =>
            object.object.document.data.filter_materials
              .filter((material) => material.single_material !== null)
              .map((material) => material.single_material.toLowerCase())
          )
          .flat()
          .includes(activeMaterial);
      }
    })
    .filter((maker) => {
      if (activeMaker === "All") {
        return maker;
      } else {
        return (
          maker.maker.document.data.name.text.toLowerCase() === activeMaker
        );
      }
    })
    .map((maker, index) => {
      const objects = maker.maker.document.data.objects
        .filter((object) => object.object.document !== null)
        .map((object, index) => (
          <p
            key={`single_object_${index}_${object.object.document.id}`}
            className="single-object"
          >
            <Link to={object.object.document.url} className="object-title">
              {
                FormatProductTitle(
                  object.object.document.data.shopify_product.title
                )[1]
              }
              {object.object.document.data.year !== null && `, `}
              {object.object.document.data.year}
            </Link>
          </p>
        ));

      return (
        <li key={`maker_id_${maker.maker.document.id}`}>
          <div className="container">
            <div className="image-container">
              {maker.maker.document.data.thumbnail.fluid !== null && (
                <AspectRatioImageContainer image={null} padding={125}>
                  <img
                    srcSet={maker.maker.document.data.thumbnail.fluid.srcSet}
                    src={maker.maker.document.data.thumbnail.fluid.src}
                    alt={maker.maker.document.data.thumbnail.alt}
                  />
                </AspectRatioImageContainer>
              )}
            </div>
            <div className="details">
              <p className="bl-arctic-regular name">
                {maker.maker.document.data.name.text}
              </p>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: maker.maker.document.data.biography.html,
                }}
              />
              <div className="maker-objects-container">{objects}</div>
            </div>
          </div>
        </li>
      );
    });

  const allMaterials = Array.from(
    new Set(
      Array.from(
        new Set(
          data.prismicBiographies.data.makers
            .filter((maker) => maker.maker.document !== null)
            .map((maker) =>
              maker.maker.document.data.objects
                .filter((object) => object.object.document !== null)
                .map((object) =>
                  object.object.document.data.filter_materials
                    .filter((material) => material.single_material !== null)
                    .map((material) => material.single_material.toLowerCase())
                )
            )
            .flat()
        )
      ).flat()
    )
  )
    .flat()
    .sort()
    .map((material) => (
      <button
        onClick={() => setActiveMaterial(material)}
        key={`single_filter_${material}`}
        className={activeMaterial === material ? `active filter` : `filter`}
      >
        <span>(</span>
        {material}
        <span>)</span>
      </button>
    ));

  const allMakers = Array.from(
    new Set(
      data.prismicBiographies.data.makers
        .filter((maker) => maker.maker.document !== null)
        .map((maker) => maker.maker.document.data.name.text.toLowerCase())
        .flat()
        .sort()
    )
  ).map((maker) => (
    <button
      onClick={() => setActiveMaker(maker)}
      key={`single_filter_${maker}`}
      className={activeMaker === maker ? `active filter` : `filter`}
    >
      <span>(</span>
      {maker}
      <span>)</span>
    </button>
  ));

  return (
    <PopupContainer
      background={`#D1BFA3`}
      setActivePopup={setActivePopup}
      activePopup={activePopup}
      padding={`90px 120px 20px 23px`}
      popupOpacity={popupOpacity}
    >
      <div className="popup-inner-container">
        <Filters>
          <div className="filter-container">
            <div className="container">
              {isFiltersOpen && (
                <button
                  onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                  className="button-left"
                >
                  filter +
                </button>
              )}
              <div className="filters-right">
                {isFiltersOpen && (
                  <button
                    onClick={() => {
                      setIsFiltersOpen(false);
                      setActiveMaker("All");
                      setActiveMaterial("All");
                    }}
                    className="clear-filter-button"
                  >
                    (clear filter)
                  </button>
                )}
                <button
                  onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                  className="button-right"
                >
                  {isFiltersOpen ? `(close)` : `filter +`}
                </button>
              </div>
            </div>

            {isFiltersOpen && (
              <div className="filter-buttons-container">
                <div className="grid bottom-line">
                  <div>
                    {allMakers.length >= 1 && (
                      <button
                        onClick={() => {
                          setActiveMaker("All");
                          setActiveMaterial("All");

                          if (openFilter === "maker") {
                            setOpenFilter(null);
                          } else {
                            setOpenFilter("maker");
                          }
                        }}
                        className={
                          openFilter === "maker" ? `active filter` : `filter`
                        }
                      >
                        <span>(</span>maker<span>)</span>
                      </button>
                    )}

                    {allMaterials.length >= 1 && (
                      <button
                        onClick={() => {
                          setActiveMaker("All");
                          setActiveMaterial("All");

                          if (openFilter === "material") {
                            setOpenFilter(null);
                          } else {
                            setOpenFilter("material");
                          }
                        }}
                        className={
                          openFilter === "material" ? `active filter` : `filter`
                        }
                      >
                        <span>(</span>material<span>)</span>
                      </button>
                    )}
                  </div>

                  <div className="filter-options">
                    {openFilter === "maker" && (
                      <div className="inner-filters-container">{allMakers}</div>
                    )}

                    {openFilter === "material" && (
                      <div className="inner-filters-container">
                        {allMaterials}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Filters>
        <List>{content}</List>
      </div>
    </PopupContainer>
  );
};
