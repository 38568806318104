import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

// Components
import { CountrySelector } from "./country-selector";

// Icons
import { SignupArrow } from "../icons/arrows";

const EnquiryFormContainer = styled.div`
  position: relative;
  align-self: flex-end;

  max-width: 1000px;

  color: #000;

  order: 12;

  & h2 {
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.03em;

    @media (max-width: 600px) {
      font-size: 9px;
      line-height: 16px;
      letter-spacing: 0.02em;
    }
  }

  @media (max-width: 1140px) {
    order: 1;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  & .signup-text {
    margin: 0 0 28px 0;

    & p {
      margin: 0;
    }

    @media (max-width: 600px) {
      & p {
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.02em;
      }
    }
  }

  & .thank-you-message,
  & form {
    max-width: 520px;
    margin: 0;

    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;

      border-bottom: 1px solid #000;

      margin: 0 0 20px 0;

      & label {
        width: 120px;
        padding: 0;

        font-size: 15px;
        line-height: 27px;
        letter-spacing: 0.03em;

        @media (max-width: 600px) {
          width: 70px;
        }

        @media (max-width: 600px) {
          font-size: 9px;
          line-height: 16px;
          letter-spacing: 0.02em;
        }
      }

      & input {
        width: calc(100% - 120px - 32px);

        padding: 0;
        margin: 0;
        border: 0;

        color: #000;
        background: transparent;

        font-size: 15px;
        line-height: 27px;
        letter-spacing: 0.03em;

        @media (max-width: 600px) {
          font-size: 9px;
          line-height: 16px;
          letter-spacing: 0.02em;
        }

        @media (max-width: 600px) {
          width: calc(100% - 100px - 20px);
        }

        ::-webkit-input-placeholder {
          font-family: "Styrene B Web", "Helvetica Neue", "Lucida Grande",
            sans-serif;

          color: #000;
        }
        ::-moz-placeholder {
          font-family: "Styrene B Web", "Helvetica Neue", "Lucida Grande",
            sans-serif;

          color: #000;
        }
        :-ms-input-placeholder {
          font-family: "Styrene B Web", "Helvetica Neue", "Lucida Grande",
            sans-serif;

          color: #000;
        }
        :-moz-placeholder {
          font-family: "Styrene B Web", "Helvetica Neue", "Lucida Grande",
            sans-serif;

          color: #000;
        }
      }
    }
  }

  & .thank-you-message {
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.03em;

    @media (max-width: 600px) {
      font-size: 9px;
      line-height: 16px;
      letter-spacing: 0.02em;
    }

    & p {
      margin: 0;
    }
  }
`;

const OptionsContainer = styled.div`
  max-width: 670px;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.03em;

  & #country {
    width: calc(100% - 120px);
  }

  @media (max-width: 600px) {
    & #country {
      width: calc(100% - 100px);
    }
  }

  @media (max-width: 600px) {
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  margin: 0 0 0 auto;

  & button {
    border: 0;

    margin: 0 0 0 20px;
    padding: 0;

    color: #000;
    line-height: 1.5;

    &:disabled,
    &[disabled] {
      &:hover,
      &:focus,
      &:focus-within {
        color: #000;
        background-color: transparent;

        -webkit-text-stroke: 0.1px #000;
      }
    }
  }

  @media (max-width: 767px) {
    & svg {
      width: 57px;
      height: auto;

      & path {
        stroke-width: 1;
      }
    }

    & button {
      margin: 0 0 0 10px;
    }
  }
`;

const ErrorsContainer = styled.div`
  margin: 1em 0 0 0;

  & p {
    margin: 0;

    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.03em;

    @media (max-width: 600px) {
      font-size: 9px;
      line-height: 16px;
      letter-spacing: 0.02em;
    }
  }
`;

export const SignUp = ({ setIsMailchimpOpen }) => {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [formMessage, setFormMessage] = useState(null);
  const [mailchimpMessage, setMailchimpMessage] = useState(null);

  const data = useStaticQuery(graphql`
    {
      prismicNewsletter {
        data {
          newsletter_confirmation_text {
            html
          }
          newsletter_text {
            html
          }
        }
      }
    }
  `);

  // Form Setup
  const {
    control,
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    formState,
    getValues,
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();

    addToMailchimp(data.email, {
      FNAME: data.fName,
      LNAME: data.lName,
      COUNTRY: data.country,
    }) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "error") {
          if (data.msg === "The email you entered is not valid.") {
            setFormMessage("Invalid Email");
          }

          if (data.msg.includes("already subscribed")) {
            setMailchimpMessage(
              "Thank you for signing up to the Metamorphoses mailing list."
            );
            setIsFormVisible(false);
            e.target.reset();
          }
        } else {
          setMailchimpMessage(
            "Thank you for signing up to the Metamorphoses mailing list."
          );
          setIsFormVisible(false);
          e.target.reset();
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  useEffect(() => {
    if (isFormVisible === false) {
      const closeForm = setTimeout(() => {
        setIsMailchimpOpen(false);
        const fadeInForm = setTimeout(() => {
          setIsFormVisible(true);
        }, 750);
        return () => clearTimeout(fadeInForm);
      }, 8000);
      return () => clearTimeout(closeForm);
    }
  }, [isFormVisible]);

  return (
    <EnquiryFormContainer className="z-index-100">
      {isFormVisible && (
        <>
          <div>
            <h2>newsletter</h2>
          </div>
          <div
            className="signup-text"
            dangerouslySetInnerHTML={{
              __html: data.prismicNewsletter.data.newsletter_text.html,
            }}
          />

          <form
            name="signup-form"
            id="holding_site_signup_form"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="signup-form" />
            <div className="field">
              <label htmlFor="fName">First name</label>
              <input
                id="fName"
                name="fName"
                type="fName"
                autoComplete="given-name"
                aria-required="true"
                aria-label="First Name"
                placeholder={errors.fName ? `This field is required` : ``}
                ref={register({
                  required: true,
                })}
              />
            </div>

            <div className="field">
              <label htmlFor="lName">Last name</label>
              <input
                id="lName"
                name="lName"
                type="text"
                autoComplete="family-name"
                aria-required="true"
                aria-label="Last Name"
                placeholder={errors.lName ? `This field is required` : ``}
                ref={register({
                  required: true,
                })}
              />
            </div>

            <div className="field">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                aria-required="true"
                aria-label="E-mail"
                placeholder={errors.email ? `Enter a valid email address` : ``}
                ref={register({
                  required: true,
                })}
              />
            </div>

            <div className="field">
              <OptionsContainer>
                <label htmlFor="country" id="downshift-0-label">
                  Country
                </label>

                <Controller
                  control={control}
                  name="country"
                  defaultValue="United Kingdom"
                  render={({ onChange, onBlur, value }) => (
                    <CountrySelector onChange={onChange} selected={value} />
                  )}
                />
              </OptionsContainer>
            </div>

            <SubmitButtonContainer>
              <SignupArrow />
              <button type="submit" className="submit-button" tabIndex="0">
                sign up
              </button>
            </SubmitButtonContainer>

            <ErrorsContainer>
              {(errors.fName || errors.lName || errors.email) && (
                <p>Please complete the missing information</p>
              )}
            </ErrorsContainer>
          </form>
        </>
      )}

      {!isFormVisible && (
        <div className="thank-you-message" id="thank_you_message">
          <div
            dangerouslySetInnerHTML={{
              __html:
                data.prismicNewsletter.data.newsletter_confirmation_text.html,
            }}
          />
        </div>
      )}
    </EnquiryFormContainer>
  );
};
