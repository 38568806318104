// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bag-js": () => import("./../../../src/pages/bag.js" /* webpackChunkName: "component---src-pages-bag-js" */),
  "component---src-pages-conversations-js": () => import("./../../../src/pages/conversations.js" /* webpackChunkName: "component---src-pages-conversations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-order-confirmation-js": () => import("./../../../src/pages/order-confirmation.js" /* webpackChunkName: "component---src-pages-order-confirmation-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shipping-and-returns-js": () => import("./../../../src/pages/shipping-and-returns.js" /* webpackChunkName: "component---src-pages-shipping-and-returns-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-splash-js": () => import("./../../../src/pages/splash.js" /* webpackChunkName: "component---src-pages-splash-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-collaboration-js": () => import("./../../../src/templates/collaboration.js" /* webpackChunkName: "component---src-templates-collaboration-js" */),
  "component---src-templates-conversation-js": () => import("./../../../src/templates/conversation.js" /* webpackChunkName: "component---src-templates-conversation-js" */),
  "component---src-templates-object-js": () => import("./../../../src/templates/object.js" /* webpackChunkName: "component---src-templates-object-js" */)
}

