import React, { useState, useContext } from "react";
import styled from "styled-components";

// Context
import { ActiveObject } from "../context/active-object";

// Components
import { PopupContainer } from "./popup-container";
import { ObjectEnquiryForm } from "../forms/object-enquiry-form";

// Utils
import { FormatProductTitle } from "../utils/format-product-title";

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const ImageContainer = styled.div`
  max-width: 210px;

  @media (max-width: 767px) {
    order: 12;
  }
`;

const Text = styled.div`
  margin: 0 0 0 20px;

  @media (max-width: 767px) {
    flex: 100%;
    width: 100%;

    order: 1;

    margin: 0 0 25px 0;
  }

  & p {
    margin: 0 0 1em 0;

    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.03em;

    @media (max-width: 960px) {
      font-size: 14px;
      line-height: 19px;
    }

    @media (max-width: 600px) {
      font-size: 9px;
      line-height: 16px;
    }
  }

  & h2 {
    font-size: 19px;
    line-height: 26px;
    letter-spacing: 0.03em;

    text-transform: lowercase;

    @media (max-width: 600px) {
      font-size: 11px;
      line-height: 16px;
    }
  }
`;

export const ObjectEnquiry = ({ setActivePopup }) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeObject, setActiveObject] = useContext(ActiveObject);

  return (
    <PopupContainer
      background={`#d2d8da`}
      setActivePopup={setActivePopup}
      padding={`93px 120px 20px 120px`}
      activePopup={`enquiry`}
    >
      <div className="popup-inner-container">
        <Flex>
          <ImageContainer>
            <img
              src={activeObject.object.product.images[0].originalSrc}
              alt={activeObject.object.product.title}
            />
          </ImageContainer>

          <Text>
            <p>please send me details of this piece</p>
            <h2>
              {FormatProductTitle(activeObject.object.product.title)[0]} <br />
              {FormatProductTitle(activeObject.object.product.title)[1]}
            </h2>
          </Text>
        </Flex>

        <ObjectEnquiryForm title={activeObject.object.product.title} />
      </div>
    </PopupContainer>
  );
};
