import React from "react";
import styled from "styled-components";

// Components
import { SignUp } from "../mailchimp/mailchimp";

const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  background: rgba(250, 250, 245, 0.92);
  overflow-y: scroll;

  padding: 105px 20px 20px 20px;
  z-index: 1;

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(6, 1fr);
    padding: 105px 15px 20px 15px;
  }
`;

const Container = styled.div`
  grid-column: span 6 / 13;

  @media (max-width: 767px) {
    grid-column: span 5;
  }

  & .close-button-container {
    margin: 0;

    @media (min-height: 500px) {
      margin: 50px 0 0 0;
    }

    @media (min-height: 700px) {
      margin: 160px 0 0 0;
    }

    & button {
      font-size: 14px;
      letter-spacing: 0.03em;

      @media (max-width: 600px) {
        font-size: 100%;
        line-height: 16px;
      }
    }
  }
`;

export const Newsletter = ({ setActivePopup, setShowNewsletter }) => (
  <Popup>
    <Container data-nosnippet>
      <SignUp />

      <div className="close-button-container">
        <button
          onClick={() => {
            setActivePopup(null);
            setShowNewsletter(false);
          }}
        >
          (close)
        </button>
      </div>
    </Container>
  </Popup>
);
