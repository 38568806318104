import React, { useState, useContext } from "react";
import styled from "styled-components";

// Context
import { ActiveObject } from "../context/active-object";

// Components
import { PopupContainer } from "./popup-container";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

const TextNoImages = styled.div`
  max-width: 650px;

  & .title {
    margin: 0 0 40px 0;

    @media (max-width: 767px) {
      & h2 {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  & .credits-text {
    margin: 2em 0 0 0;
  }

  & .text {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.02em;

    & strong {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.02em;

      font-style: normal;
      font-weight: normal;
      text-transform: lowercase;

      @media (max-width: 960px) {
        font-size: 9px;
        line-height: 12px;
      }
    }

    & a {
      &::before {
        content: "(";
      }
      &::after {
        content: ")";
      }
    }

    @media (max-width: 960px) {
      font-size: 14px;
      line-height: 19px;
    }

    @media (max-width: 600px) {
      font-size: 11px;
      line-height: 16px;
    }
  }
`;

const TextAndImages = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;

  height: 100%;

  & .title-and-images {
    position: relative;
    width: 100%;

    margin: 0 0 40px 0;
  }

  & .title {
    margin: 0 0 40px 0;

    @media (max-width: 767px) {
      & h2 {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  & .credits-text {
    margin: 2em 0 0 0;
  }

  & .image-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 80px;
    grid-row-gap: 80px;

    @media (max-width: 960px) {
      grid-column-gap: 25px;
      grid-row-gap: 25px;
    }

    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-row-gap: 25px;
      grid-column-gap: 0;
    }

    & .process-image-container {
      &.portrait {
        grid-column: span 1;
      }

      &.landscape {
        grid-column: span 2;
      }

      & .content {
        mix-blend-mode: multiply;
        background: #e1e4d9;
      }

      & img {
        object-fit: cover;
        width: 100%;
        height: 100%;

        mix-blend-mode: multiply;
        filter: grayscale(1);
      }

      & .caption {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;

        @media (max-width: 960px) {
          font-size: 9px;
          line-height: 12px;
        }
      }
    }
  }

  & .text-container {
    max-width: 650px;

    & .text {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;

      align-self: flex-end;

      & strong {
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;

        font-style: normal;
        font-weight: normal;
        text-transform: lowercase;

        @media (max-width: 960px) {
          font-size: 9px;
          line-height: 12px;
        }
      }

      & a {
        &::before {
          content: "(";
        }
        &::after {
          content: ")";
        }
      }

      & p:first-of-type {
        margin-top: 0;
      }

      & p:last-of-type {
        margin-bottom: 0;
      }

      @media (max-width: 960px) {
        font-size: 14px;
        line-height: 19px;
      }

      @media (max-width: 600px) {
        font-size: 11px;
        line-height: 16px;
      }
    }
  }
`;

export const ObjectProcess = ({ setActivePopup }) => {
  const [activeObject, setActiveObject] = useContext(ActiveObject);

  const processImages = activeObject.object.data.images
    .filter((image) => image.image.fluid !== null)
    .map((image, index) => (
      <div
        className={`process-image-container ${ImageOrientation(image.image)}`}
        key={`single_process_image_${index}`}
      >
        {image.image.fluid !== null && (
          <AspectRatioImageContainer
            image={null}
            padding={
              image.image.dimensions.width > image.image.dimensions.height
                ? 58.5
                : 133
            }
          >
            <img
              className="process-image"
              srcSet={image.image.fluid.srcSet}
              src={image.image.fluid.src}
              alt={image.image.alt}
              loading="lazy"
            />
          </AspectRatioImageContainer>
        )}
        <div
          className="caption"
          dangerouslySetInnerHTML={{
            __html: image.caption.html,
          }}
        />
      </div>
    ));

  return (
    <PopupContainer
      background={`#e1e4d9`}
      setActivePopup={setActivePopup}
      padding={`90px 120px 20px 120px`}
      activePopup={`objects`}
    >
      <div className="popup-inner-container">
        {processImages.length === 0 && (
          <TextNoImages>
            <div
              className="title bl-arctic-regular"
              dangerouslySetInnerHTML={{
                __html: activeObject.object.data.section_title.html,
              }}
            />
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: activeObject.object.data.text.html,
              }}
            />
            <div
              className="credits-text small-text"
              dangerouslySetInnerHTML={{
                __html: activeObject.object.data.credits.html,
              }}
            />
          </TextNoImages>
        )}

        {processImages.length >= 1 && (
          <TextAndImages>
            <div className="title-and-images">
              <div
                className="title bl-arctic-regular"
                dangerouslySetInnerHTML={{
                  __html: activeObject.object.data.section_title.html,
                }}
              />
              <div className="image-container">{processImages}</div>
            </div>
            <div className="text-container">
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: activeObject.object.data.text.html,
                }}
              />
              <div
                className="credits-text small-text"
                dangerouslySetInnerHTML={{
                  __html: activeObject.object.data.credits.html,
                }}
              />
            </div>
          </TextAndImages>
        )}
      </div>
    </PopupContainer>
  );
};

// import React, { useState, useContext } from "react";
// import styled from "styled-components";

// // Context
// import { ActiveObject } from "../context/active-object";

// // Components
// import { PopupContainer } from "./popup-container";
// import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// // Utils
// import { ImageOrientation } from "../utils/image-orientation";

// const TextNoImages = styled.div`
//   max-width: 650px;

//   & .title {
//     margin: 0 0 40px 0;

//     @media (max-width: 767px) {
//       & h2 {
//         font-size: 14px;
//         line-height: 20px;
//       }
//     }
//   }

//   & .text {
//     font-size: 16px;
//     line-height: 22px;
//     letter-spacing: 0.02em;

//     & a {
//       &::before {
//         content: "(";
//       }
//       &::after {
//         content: ")";
//       }
//     }

//     @media (max-width: 960px) {
//       font-size: 14px;
//       line-height: 19px;
//     }

//     @media (max-width: 600px) {
//       font-size: 11px;
//       line-height: 16px;
//     }
//   }
// `;

// const TextAndImages = styled.div`
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   align-content: flex-start;

//   height: 100%;

//   & .title {
//     margin: 0 0 40px 355px;

//     @media (max-width: 1200px) {
//       margin: 0 0 40px 235px;
//     }

//     @media (max-width: 960px) {
//       margin: 0 0 40px 0;
//     }

//     @media (max-width: 767px) {
//       & h2 {
//         font-size: 14px;
//         line-height: 20px;
//       }
//     }
//   }

//   & .title-and-images {
//     position: relative;
//     width: 100%;
//   }

//   & .text-image-container {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     align-content: flex-start;

//     & .image-container {
//       width: 100%;
//       max-width: 255px;

//       order: 1;

//       @media (max-width: 1200px) {
//         max-width: 185px;
//       }

//       @media (max-width: 960px) {
//         order: 12;
//       }

//       @media (max-width: 600px) {
//         max-width: 95px;
//       }

//       & .process-image-container {
//         margin: 0 0 65px 0;

//         @media (max-width: 960px) {
//           margin: 0 0 40px 0;
//         }

//         &.portrait {
//           // grid-column: span 1;
//           width: 75%;

//           @media (max-width: 960px) {
//             width: 100%;
//           }
//         }

//         &.landscape {
//           // grid-column: span 2;
//           width: 100%;
//         }

//         & .content {
//           // mix-blend-mode: multiply;
//           // background: #e1e4d9;
//         }

//         & img {
//           // object-fit: cover;
//           // width: 100%;
//           // height: 100%;

//           // mix-blend-mode: multiply;
//           // filter: grayscale(1);
//         }

//         & .caption {
//           font-size: 14px;
//           line-height: 19px;
//           letter-spacing: 0.02em;

//           @media (max-width: 960px) {
//             font-size: 9px;
//             line-height: 12px;
//           }
//         }
//       }
//     }
//   }

//   & .text-container {
//     max-width: 680px;
//     padding: 0 0 0 100px;
//     flex: 1;
//     order: 12;

//     @media (max-width: 1200px) {
//       padding: 0 0 0 50px;
//     }

//     @media (max-width: 960px) {
//       flex: 100%;
//       padding: 0 0 20px 0;

//       order: 1;
//     }

//     & .text {
//       font-size: 16px;
//       line-height: 22px;
//       letter-spacing: 0.02em;

//       align-self: flex-end;

//       & a {
//         &::before {
//           content: "(";
//         }
//         &::after {
//           content: ")";
//         }
//       }

//       & p:first-of-type {
//         margin-top: 0;
//       }

//       & p:last-of-type {
//         margin-bottom: 0;
//       }

// & strong {
//   font-size: 14px;
//   line-height: 19px;
//   letter-spacing: 0.02em;

//   font-style: normal;
//   font-weight: normal;

//   @media (max-width: 960px) {
//     font-size: 9px;
//     line-height: 12px;
//   }
// }

//       @media (max-width: 960px) {
//         font-size: 14px;
//         line-height: 19px;
//       }

//       @media (max-width: 600px) {
//         font-size: 11px;
//         line-height: 16px;
//       }
//     }
//   }
// `;

// export const ObjectProcess = ({ setActivePopup }) => {
//   const [activeObject, setActiveObject] = useContext(ActiveObject);

//   const processImages = activeObject.object.data.images
//     .filter((image) => image.image.fluid !== null)
//     .map((image, index) => (
//       <div
//         className={`process-image-container ${ImageOrientation(image.image)}`}
//         key={`single_process_image_${index}`}
//       >
//         {image.image.fluid !== null && (
//           <img
//             className="process-image"
//             srcSet={image.image.fluid.srcSet}
//             src={image.image.fluid.src}
//             alt={image.image.alt}
//             loading="lazy"
//           />
//         )}
//         <div
//           className="caption"
//           dangerouslySetInnerHTML={{
//             __html: image.caption.html,
//           }}
//         />
//       </div>
//     ));

//   return (
//     <PopupContainer
//       background={`#e1e4d9`}
//       setActivePopup={setActivePopup}
//       padding={`90px 120px 20px 23px`}
//       activePopup={`objects`}
//     >
//       <div className="popup-inner-container">
//         {processImages.length === 0 && (
//           <TextNoImages>
//             <div
//               className="title bl-arctic-regular"
//               dangerouslySetInnerHTML={{
//                 __html: activeObject.object.data.section_title.html,
//               }}
//             />
//             <div
//               className="text"
//               dangerouslySetInnerHTML={{
//                 __html: activeObject.object.data.text.html,
//               }}
//             />
//             <div
//               className="credits-text small-text"
//               dangerouslySetInnerHTML={{
//                 __html: activeObject.object.data.credits.html,
//               }}
//             />
//           </TextNoImages>
//         )}

//         {processImages.length >= 1 && (
//           <TextAndImages>
//             <div className="title-and-images">
//               <div
//                 className="title bl-arctic-regular"
//                 dangerouslySetInnerHTML={{
//                   __html: activeObject.object.data.section_title.html,
//                 }}
//               />
//             </div>

//             <div className="text-image-container">
//               <div className="image-container">{processImages}</div>
//               <div className="text-container">
//                 <div
//                   className="text"
//                   dangerouslySetInnerHTML={{
//                     __html: activeObject.object.data.text.html,
//                   }}
//                 />
//                 <div
//                   className="credits-text small-text"
//                   dangerouslySetInnerHTML={{
//                     __html: activeObject.object.data.credits.html,
//                   }}
//                 />
//               </div>
//             </div>
//           </TextAndImages>
//         )}
//       </div>
//     </PopupContainer>
//   );
// };
