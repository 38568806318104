import React from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";
import { CookiesComponent } from "./cookies";

// Context
import Provider from "../context/provider";

const Container = styled.div`
  position: relative;

  display: flex;
  min-height: 100vh;
  flex-direction: column;

  width: 100%;
  padding: 0 20px;

  @media (max-width: 767px) {
    padding: 0 15px;
    min-height: 100%;

    &.single-collaboration {
      padding: 0;
      min-height: unset;
      overflow: hidden;
    }
  }
`;

const Layout = ({ children }) => (
  <Provider>
    <Location>
      {({ location }) => {
        return (
          <Container
            location={location.pathname}
            id="outer-container"
            className={
              location.pathname.includes("collaborations")
                ? `single-collaboration`
                : ``
            }
          >
            <Normalize />
            <GlobalStyles />
            <DefaultSEO location={location} />
            <App location={location.pathname} children={children} />
            <CookiesComponent location={location.pathname} />
          </Container>
        );
      }}
    </Location>
  </Provider>
);

export default Layout;
