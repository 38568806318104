import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";

const CookieContainer = styled.div`
  z-index: 100;

  & .privacy-policy-button {
    cursor: pointer;

    margin: 0 25px 0 55px;

    @media (max-width: 940px) {
      margin: 0;
    }
  }

  & .cookie-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: fit-content;
    /* right: 0; */

    z-index: 100;
    background-color: #fafaf5;

    padding: 19px 20px 20px 20px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;

    @media (max-width: 940px) {
      max-width: 270px;
    }

    @media (max-width: 600px) {
      max-width: 148px;
      padding: 15px;

      font-size: 8px;
      line-height: 12px;
    }
  }

  & .content-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & p {
      margin: 0;
    }
  }

  & .cookie-text {
    & p {
      margin: 0;
    }

    @media (max-width: 940px) {
      margin: 0 0 1em 0;
    }
  }

  & .cookie-button {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;

    border: 0;
    margin: 0;
    padding: 0;

    cursor: pointer;

    @media (max-width: 600px) {
      font-size: 8px;
      line-height: 12px;
    }
  }

  & .button-container {
    @media (max-width: 940px) {
      position: absolute;
      right: 34px;
      bottom: 34px;
    }

    @media (max-width: 600px) {
      right: 15px;
      bottom: 15px;
    }
  }
`;

export const CookiesComponent = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      prismicPrivacyPolicy {
        data {
          cookie_text {
            html
          }
        }
      }
    }
  `);

  return (
    <CookieContainer data-nosnippet>
      <CookieConsent
        location="bottom"
        buttonText="(accept)"
        cookieName="metamorphoses-gdpr-google-analytics"
        containerClasses="cookie-container"
        contentClasses="content-container"
        buttonWrapperClasses="button-container"
        buttonClasses="cookie-button"
        disableStyles={true}
        onAccept={() => {
          initializeAndTrack(location);
        }}
        expires={150}
      >
        <div
          className="cookie-text"
          dangerouslySetInnerHTML={{
            __html: data.prismicPrivacyPolicy.data.cookie_text.html,
          }}
        />

        <Link to={`/privacy-policy/`} className="privacy-policy-button">
          (learn more)
        </Link>
      </CookieConsent>
    </CookieContainer>
  );
};
