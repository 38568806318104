import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Context
import { AddedToBag } from "../context/added-to-bag";

// Utils
import { FormatProductTitle } from "../utils/format-product-title";

const Popup = styled.div`
  position: fixed;
  top: 0;
  right: 0;

  background: rgba(250, 250, 245, 0.92);
  overflow-y: scroll;

  padding: 105px 70px 20px 105px;
  z-index: 1;

  @media (max-width: 1180px) {
    left: 0;
    padding: 105px 20px 20px 20px;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 10px;
  }

  @media (max-width: 500px) {
    padding: 55px 20px 20px 20px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 0 auto;

  @media (max-width: 1180px) {
    grid-column: 2 / 7;
  }
`;

const ImageContainer = styled.div`
  max-width: 210px;

  @media (max-width: 767px) {
    max-width: 100px;
  }
`;

const Text = styled.div`
  margin: 0 0 0 20px;

  @media (max-width: 767px) {
    margin: 0 0 0 10px;
  }

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & .container {
    width: 100%;
  }

  & .bottom-container {
    align-self: flex-end;

    & p {
      margin: 0;
      color: rgb(159, 95, 85);
    }
  }

  & p {
    margin: 0 0 1em 0;

    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.03em;

    @media (max-width: 960px) {
      font-size: 14px;
      line-height: 19px;
    }

    @media (max-width: 600px) {
      font-size: 9px;
      line-height: 16px;
    }
  }

  & h2 {
    font-size: 19px;
    line-height: 26px;
    letter-spacing: 0.03em;

    text-transform: lowercase;

    @media (max-width: 960px) {
      font-size: 16px;
      line-height: 26px;
    }

    @media (max-width: 600px) {
      font-size: 11px;
      line-height: 16px;
    }
  }
`;

export const AddToBag = ({ setActivePopup }) => {
  const [activeBagItem, setActiveBagItem] = useContext(AddedToBag);

  useEffect(() => {
    const addToBagTimer = setTimeout(() => {
      setActivePopup(null);
      setActiveBagItem(null);
    }, 3000);
    return () => clearTimeout(addToBagTimer);
  }, []);

  return (
    <Popup>
      <Flex>
        <ImageContainer>
          <img
            src={activeBagItem.images[0].originalSrc}
            alt={activeBagItem.title}
          />
        </ImageContainer>

        <Text>
          <div className="container top-container">
            <p>added to bag</p>
            <h2>
              {FormatProductTitle(activeBagItem.title)[0]} <br />
              {FormatProductTitle(activeBagItem.title)[1]}
            </h2>
          </div>

          <div className="container bottom-container">
            <p>
              <Link to={`/bag/`}>(view bag)</Link>
            </p>
          </div>
        </Text>
      </Flex>
    </Popup>
  );
};
