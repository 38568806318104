import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  @media (max-width: 600px) {
    width: auto;
    height: 18px;
  }
`;

export const BasketEmpty = () => (
  <Icon
    width="17"
    height="22"
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 5.75V21.25H16.25V5.75H0.75ZM17 5V22H0V5H5C5 2.23858 6.79086 0 9 0C10.1146 0 11.1227 0.56986 11.8481 1.48921C12.5604 2.39201 13 3.63183 13 5H17ZM12.2008 4.25C12.233 4.4911 12.25 4.74145 12.25 5H5.75C5.75 4.74145 5.76698 4.4911 5.79923 4.25C6.08037 2.14862 7.52216 0.75 9 0.75C10.4778 0.75 11.9196 2.14862 12.2008 4.25Z"
      fill="black"
      strokeWidth="0.7"
    />
  </Icon>
);

export const BasketFull = () => (
  <Icon
    width="17"
    height="22"
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 5H12.25C12.25 2.48797 10.6474 0.75 9 0.75C7.3526 0.75 5.75 2.48797 5.75 5ZM5 5H0V22H17V5H13C13 2.23858 11.2091 0 9 0C6.79086 0 5 2.23858 5 5Z"
        strokeWidth="0.7"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.75 5H12.25C12.25 2.48797 10.6474 0.75 9 0.75C7.3526 0.75 5.75 2.48797 5.75 5ZM5 5H0V22H17V5H13C13 2.23858 11.2091 0 9 0C6.79086 0 5 2.23858 5 5Z"
      fill="#9F5F55"
      strokeWidth="0.7"
    />
    <path
      d="M5.75 5H5V5.75H5.75V5ZM12.25 5V5.75H13V5H12.25ZM0 5V4.25H-0.75V5H0ZM5 5V5.75H5.75V5H5ZM0 22H-0.75V22.75H0V22ZM17 22V22.75H17.75V22H17ZM17 5H17.75V4.25H17V5ZM13 5H12.25V5.75H13V5ZM5.75 5.75H12.25V4.25H5.75V5.75ZM13 5C13 2.22876 11.2012 0 9 0V1.5C10.0936 1.5 11.5 2.74718 11.5 5H13ZM9 0C6.79877 0 5 2.22876 5 5H6.5C6.5 2.74718 7.90643 1.5 9 1.5V0ZM0 5.75H5V4.25H0V5.75ZM0.75 22V5H-0.75V22H0.75ZM17 21.25H0V22.75H17V21.25ZM16.25 5V22H17.75V5H16.25ZM13 5.75H17V4.25H13V5.75ZM13.75 5C13.75 1.98919 11.7709 -0.75 9 -0.75V0.75C10.6474 0.75 12.25 2.48797 12.25 5H13.75ZM9 -0.75C6.22912 -0.75 4.25 1.98919 4.25 5H5.75C5.75 2.48797 7.3526 0.75 9 0.75V-0.75Z"
      fill="#9F5F55"
      mask="url(#path-1-inside-1)"
      strokeWidth="0.7"
    />
  </Icon>
);
