import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  overflow: hidden;

  @media (max-width: 1400px) {
    width: 350px;
    height: auto;
  }

  @media (max-width: 1140px) {
    width: 300px;
    height: auto;
  }
`;

const Path = styled.path`
  visibility: ${(props) => props.visibility};
  transform: translateX(${(props) => props.largeX}px);
  transition: ${(props) => props.transition}ms;
  transition-delay: ${(props) => props.delay}ms;
`;

export const Logo = ({ x, isLogoVisible, delay, transition }) => (
  <Icon
    width="452"
    height="64"
    viewBox="0 0 452 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <Path
      delay={0}
      largeX={0}
      mediumX={x}
      smallX={x}
      visibility={isLogoVisible ? `visible` : `hidden`}
      transition={transition}
      d="M47 48.3941V49H32.3996V48.3941C35.4435 48.3941 37.9715 48.3941 37.9715 47.0814V25.5733C37.9715 22.8974 37.146 19.9186 33.3282 19.9186C29.8716 19.9186 26.3117 23.2508 25.2283 24.513V25.0179V47.0814C25.2283 48.3941 27.7563 48.3941 30.8002 48.3941V49H16.1998V48.3941C19.2437 48.3941 21.7717 48.3941 21.7717 47.0814V25.5733C21.7717 22.8974 20.9462 19.9186 17.1284 19.9186C13.4138 19.9186 9.75082 23.8062 9.02854 24.816V47.0309C9.02854 48.3436 11.5565 48.3436 14.6004 48.3436V48.9495H0V48.3941C3.04391 48.3941 5.5719 48.3941 5.5719 47.0814V20.6759C5.5719 19.3632 3.04391 19.3632 0 19.3632V18.7573H9.02854V23.9072C11.6081 20.7769 15.2711 18.0505 18.573 18.0505C22.0812 18.0505 24.7124 19.9691 25.1767 23.7557C27.8595 20.6254 31.4709 18 34.7728 18C38.6937 18 41.4797 20.4235 41.4797 25.0179V47.0814C41.4797 48.3941 44.0077 48.3941 47 48.3941Z"
      fill="black"
    />
    <Path
      delay={delay * 1.25}
      largeX={isLogoVisible ? 0 : 386}
      mediumX={x}
      smallX={x}
      visibility={isLogoVisible ? `visible` : `hidden`}
      transition={transition}
      d="M54 33.6952C54 24.5524 59.8263 18 66.9889 18C72.2272 18 76.2895 21.3524 76.2895 24.9079C76.2895 26.127 75.7016 26.9397 73.6169 26.9397H57.8485C55.8708 36.1333 59.5056 46.6984 67.7906 46.6984C71.3719 46.6984 75.8085 45.2254 77.3586 37.9111H78C76.6637 46.2413 70.8909 50 66.6147 50C59.3987 49.8984 54 42.8381 54 33.6952ZM70.3029 26.127C71.6392 26.127 72.2272 25.4667 72.2272 24.5524C72.2272 21.2 69.608 18.8127 65.5991 18.8127C61.5367 18.8127 59.078 22.0635 57.9555 26.127H70.3029Z"
      fill="black"
    />
    <Path
      delay={delay * 1.5}
      largeX={isLogoVisible ? 0 : 364}
      mediumX={x}
      smallX={x}
      visibility={isLogoVisible ? `visible` : `hidden`}
      transition={transition}
      d="M101 43.7596C99.6913 47.3402 97.6275 50 94.6577 50C90.9329 50 89.8255 47.1356 89.8255 43.913V19.2072H86V18.5934H86.604C89.1208 18.5934 92.4429 14.8593 92.4429 10H93.047V18.5934H100.346V19.2072H93.047C93.047 27.3913 92.9966 38.3887 92.9966 44.0665C92.9966 46.624 94.1544 48.0563 95.9161 48.0563C97.0738 48.0563 98.9866 47.5448 100.446 43.555L101 43.7596Z"
      fill="black"
    />
    <Path
      delay={delay * 1.75}
      largeX={isLogoVisible ? 0 : 332}
      mediumX={x}
      smallX={x}
      visibility={isLogoVisible ? `visible` : `hidden`}
      transition={transition}
      d="M134 45.1026C133.263 46.3648 131.526 49.9495 128.421 49.9495C125.632 49.9495 124.895 47.8795 124.895 45.658V42.2248C123.421 46.7687 119.579 50 115.263 50C111.421 50 108 47.8795 108 43.5879C108 38.6401 112.737 36.5195 118.684 33.7932C122.895 31.8746 126.053 29.2997 126.263 25.0586C126.263 23.443 125.579 22.0293 123.053 22.0293H109.158L111.421 19H115.263C122.579 19 128.474 22.2313 128.474 28.3404V45.0521C128.474 46.7687 128.895 47.8795 130.263 47.8795C131.737 47.8795 132.789 46.0114 133.474 44.7997L134 45.1026ZM124.947 38.5896V30.7134C123.053 32.6319 119.842 34.399 117.789 35.4593C113.632 37.6808 111.684 40.0537 111.684 42.8306C111.684 45.9104 113.316 47.8795 116.684 47.8795C121.579 47.8795 124.947 43.6889 124.947 38.5896ZM125.684 29.9055C125.684 29.9055 125.684 29.9055 125.684 29.956V29.9055Z"
      fill="black"
    />
    <Path
      delay={delay * 2}
      largeX={isLogoVisible ? 0 : 279}
      mediumX={x}
      smallX={x}
      visibility={isLogoVisible ? `visible` : `hidden`}
      transition={transition}
      d="M188 48.3931V49H173.4V48.3931C176.443 48.3931 178.971 48.3931 178.971 47.0783V25.5351C178.971 22.8548 178.146 19.8711 174.328 19.8711C170.872 19.8711 167.312 23.2088 166.228 24.4731V24.9788V47.0783C166.228 48.3931 168.756 48.3931 171.8 48.3931V49H157.2V48.3931C160.244 48.3931 162.772 48.3931 162.772 47.0783V25.5351C162.772 22.8548 161.946 19.8711 158.128 19.8711C154.414 19.8711 150.751 23.7651 150.029 24.7765V47.0277C150.029 48.3426 152.557 48.3426 155.6 48.3426V48.9494H141V48.3931C144.044 48.3931 146.572 48.3931 146.572 47.0783V20.6297C146.572 19.3148 144.044 19.3148 141 19.3148V18.708H150.029V23.8662C152.608 20.7308 156.271 18 159.573 18C163.081 18 165.712 19.9217 166.177 23.7145C168.859 20.6297 172.471 18 175.773 18C179.694 18 182.48 20.4274 182.48 25.0294V47.1289C182.428 48.3931 185.008 48.3931 188 48.3931Z"
      fill="black"
    />
    <Path
      delay={delay * 2.25}
      largeX={isLogoVisible ? 0 : 246}
      mediumX={x}
      smallX={x}
      visibility={isLogoVisible ? `visible` : `hidden`}
      transition={transition}
      d="M194 33.8734C194 24.1568 199.459 17.8995 206.5 18.0012C213.439 18.103 219 24.1568 219 33.8734C219 43.3866 213.439 50 206.5 50C199.459 50 194 43.4883 194 33.8734ZM215.224 32.6525C214.255 24.0042 209.969 17.9503 204.612 18.6117C199.306 19.273 196.704 26.4461 197.724 35.0944C198.694 43.9462 203.133 49.6948 208.286 49.1352C213.439 48.5756 216.194 41.5552 215.224 32.6525Z"
      fill="black"
    />
    <Path
      delay={delay * 2.5}
      largeX={isLogoVisible ? 0 : 216}
      mediumX={x}
      smallX={x}
      visibility={isLogoVisible ? `visible` : `hidden`}
      transition={transition}
      d="M247 19V22H242.45C237.423 22 235.783 23.2 235.783 29.5V46.95C235.783 48.4 237.952 48.4 241.074 48.4V49H227V48.35C230.122 48.35 232.291 48.35 232.291 46.9V21.05C232.291 19.6 230.122 19.6 227 19.6V19H235.836V25.1C236.788 21.45 240.492 19 246.048 19H247Z"
      fill="black"
    />
    <Path
      delay={delay * 2.75}
      largeX={isLogoVisible ? 0 : 180}
      mediumX={x}
      smallX={x}
      visibility={isLogoVisible ? `visible` : `hidden`}
      transition={transition}
      d="M285 34.0313C285 44.14 279.303 50.0622 272.979 50.0622C270.209 50.0622 267.073 48.2243 264.146 45.2632V62.0599C264.146 63.3874 266.707 63.3874 269.791 63.3874V64H255V63.3874C258.084 63.3874 260.645 63.3874 260.645 62.0599V20.6551C260.645 19.3277 258.084 19.3277 255 19.3277V18.7151H264.146V23.5652C267.073 20.553 270.209 18.0003 272.979 18.0003C279.303 17.9493 285 23.9226 285 34.0313ZM281.446 34.0313C281.446 24.7395 275.801 20.1446 271.411 20.1446C268.693 20.1446 266.08 22.6973 264.146 24.5352V44.2931C266.08 46.1311 268.746 48.0711 271.411 48.0711C275.801 48.0711 281.446 43.3231 281.446 34.0313Z"
      fill="black"
    />
    <Path
      delay={delay * 3}
      largeX={isLogoVisible ? 0 : 139}
      mediumX={x}
      smallX={x}
      visibility={isLogoVisible ? `visible` : `hidden`}
      transition={transition}
      d="M325 48.3869V49H310.223V48.3869C313.304 48.3869 315.862 48.3869 315.862 47.0584V25.3942C315.862 22.6861 315.131 19.6204 311.215 19.6204C307.403 19.6204 302.078 23.6569 301.138 24.6277V47.0584C301.138 48.3869 303.696 48.3869 306.777 48.3869V49H292V48.3869C295.081 48.3869 297.639 48.3869 297.639 47.0584V1.94161C297.639 0.613139 295.081 0.613139 292 0.613139V0H301.138V23.8613C304.323 20.5401 309.492 17.6788 312.886 17.6788C316.854 17.6788 319.361 20.1314 319.361 24.781V47.1095C319.361 48.3869 321.972 48.3869 325 48.3869Z"
      fill="black"
    />
    <Path
      delay={delay * 3.25}
      largeX={isLogoVisible ? 0 : 104}
      mediumX={x}
      smallX={x}
      visibility={isLogoVisible ? `visible` : `hidden`}
      transition={transition}
      d="M332 33.8734C332 24.1568 337.459 17.8995 344.5 18.0012C351.439 18.103 357 24.1568 357 33.8734C357 43.3866 351.439 50 344.5 50C337.459 50 332 43.4883 332 33.8734ZM353.224 32.6525C352.255 24.0042 347.969 17.9503 342.612 18.6117C337.306 19.273 334.704 26.4461 335.724 35.0944C336.694 43.9462 341.133 49.6948 346.286 49.1352C351.439 48.5756 354.194 41.5552 353.224 32.6525Z"
      fill="black"
    />
    <Path
      delay={delay * 3.75}
      largeX={isLogoVisible ? 0 : 73}
      mediumX={x}
      smallX={x}
      visibility={isLogoVisible ? `visible` : `hidden`}
      transition={transition}
      d="M367 46.7949V41.5548C371.18 46.1335 375.158 49.6948 380.547 48.779C383.82 48.2194 385.633 45.9809 385.633 43.3355C385.633 40.3339 384.676 36.5692 376.82 34.6868C371.18 33.3641 367.504 30.159 367.504 25.9873C367.504 20.5946 373.295 18 377.273 18C380.799 18 384.022 19.3227 385.432 20.3911L385.482 24.9189C384.223 23.3418 379.137 17.5421 373.446 19.3736C370.978 20.1876 369.216 21.9173 369.216 24.1558C369.216 26.9539 370.072 29.345 378.23 31.2782C386.036 33.1606 388 37.6884 388 41.4531C388 45.93 383.568 50 377.223 50C373.496 49.8983 370.676 48.8299 367 46.7949Z"
      fill="black"
    />
    <Path
      delay={delay * 4}
      largeX={isLogoVisible ? 0 : 41}
      mediumX={x}
      smallX={x}
      visibility={isLogoVisible ? `visible` : `hidden`}
      transition={transition}
      d="M398 33.6952C398 24.5524 403.583 18 410.448 18C415.468 18 419.361 21.3524 419.361 24.9079C419.361 26.127 418.797 26.9397 416.8 26.9397H401.688C399.793 36.1333 403.276 46.6984 411.216 46.6984C414.648 46.6984 418.9 45.2254 420.385 37.9111H421C419.719 46.2413 414.187 50 410.089 50C403.174 49.8984 398 42.8381 398 33.6952ZM413.624 26.127C414.904 26.127 415.468 25.4667 415.468 24.5524C415.468 21.2 412.958 18.8127 409.116 18.8127C405.223 18.8127 402.866 22.0635 401.791 26.127H413.624Z"
      fill="black"
    />
    <Path
      delay={delay * 4.25}
      largeX={isLogoVisible ? 0 : 21}
      mediumX={x}
      smallX={x}
      visibility={isLogoVisible ? `visible` : `hidden`}
      transition={transition}
      d="M430 46.7949V41.5548C434.379 46.1335 438.547 49.6948 444.192 48.779C447.621 48.2194 449.52 45.9809 449.52 43.3355C449.52 40.3339 448.518 36.5692 440.288 34.6868C434.379 33.3641 430.528 30.159 430.528 25.9873C430.528 20.5946 436.595 18 440.763 18C444.456 18 447.832 19.3227 449.309 20.3911L449.362 24.9189C448.043 23.3418 442.715 17.5421 436.753 19.3736C434.168 20.1876 432.321 21.9173 432.321 24.1558C432.321 26.9539 433.218 29.345 441.765 31.2782C449.942 33.1606 452 37.6884 452 41.4531C452 45.93 447.357 50 440.71 50C436.753 49.8983 433.851 48.8299 430 46.7949Z"
      fill="black"
    />
  </Icon>
);
