import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";

// Components
import { CountrySelector } from "../mailchimp/country-selector";

// Utils
import { encodeForm } from "../utils/encode-form";

// Icons
import { SignupArrow } from "../icons/arrows";

const EnquiryFormContainer = styled.div`
  margin: 20px 0 0 0;

  position: relative;
  align-self: flex-end;

  max-width: 1000px;

  color: #000;

  order: 12;

  & h2 {
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.03em;

    @media (max-width: 600px) {
      font-size: 9px;
      line-height: 16px;
      letter-spacing: 0.02em;
    }
  }

  @media (max-width: 1140px) {
    order: 1;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  & .signup-text {
    margin: 0 0 28px 0;

    & p {
      margin: 0;
    }

    @media (max-width: 600px) {
      & p {
        font-size: 11px;
        line-height: 16px;
        letter-spacing: 0.02em;
      }
    }
  }

  & .thank-you-message,
  & form {
    max-width: 520px;
    margin: 0;

    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;

      border-bottom: 1px solid #000;

      margin: 0 0 20px 0;

      & label {
        width: 120px;
        padding: 0;

        font-size: 15px;
        line-height: 27px;
        letter-spacing: 0.03em;

        text-transform: lowercase;

        @media (max-width: 600px) {
          width: 70px;
        }

        @media (max-width: 600px) {
          font-size: 9px;
          line-height: 16px;
          letter-spacing: 0.02em;
        }
      }

      & textarea {
        width: 100%;
        background-color: transparent;

        padding: 0;
        border: 0;

        font-size: 15px;
        line-height: 27px;
        letter-spacing: 0.03em;
      }

      & input {
        width: calc(100% - 120px - 32px);

        padding: 0;
        margin: 0;
        border: 0;

        color: #000;
        background: transparent;

        font-size: 15px;
        line-height: 27px;
        letter-spacing: 0.03em;

        @media (max-width: 600px) {
          font-size: 9px;
          line-height: 16px;
          letter-spacing: 0.02em;
        }

        @media (max-width: 600px) {
          width: calc(100% - 100px - 20px);
        }

        ::-webkit-input-placeholder {
          font-family: "Styrene B Web", "Helvetica Neue", "Lucida Grande",
            sans-serif;

          color: #000;
        }
        ::-moz-placeholder {
          font-family: "Styrene B Web", "Helvetica Neue", "Lucida Grande",
            sans-serif;

          color: #000;
        }
        :-ms-input-placeholder {
          font-family: "Styrene B Web", "Helvetica Neue", "Lucida Grande",
            sans-serif;

          color: #000;
        }
        :-moz-placeholder {
          font-family: "Styrene B Web", "Helvetica Neue", "Lucida Grande",
            sans-serif;

          color: #000;
        }
      }
    }
  }

  & .thank-you-message {
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.03em;

    @media (max-width: 600px) {
      font-size: 9px;
      line-height: 16px;
      letter-spacing: 0.02em;
    }

    & p {
      margin: 0;
    }
  }
`;

const OptionsContainer = styled.div`
  max-width: 670px;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  font-size: 15px;
  line-height: 27px;
  letter-spacing: 0.03em;

  & #country {
    width: calc(100% - 120px);
  }

  @media (max-width: 600px) {
    & #country {
      width: calc(100% - 100px);
    }
  }

  @media (max-width: 600px) {
    font-size: 9px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
`;

const SubmitButtonContainer = styled.div`
  margin: 0 0 0 auto;

  & button {
    border: 0;

    margin: 0 0 0 20px;
    padding: 0;

    color: #000;

    line-height: 1.5;

    &:disabled,
    &[disabled] {
      &:hover,
      &:focus,
      &:focus-within {
        color: #000;
        background-color: transparent;

        -webkit-text-stroke: 0.1px #000;
      }
    }
  }
`;

const ErrorsContainer = styled.div`
  & p {
    margin: 0;

    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.03em;

    @media (max-width: 600px) {
      font-size: 9px;
      line-height: 16px;
      letter-spacing: 0.02em;
    }
  }
`;

export const ObjectEnquiryForm = ({ title }) => {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [formMessage, setFormMessage] = useState(null);
  const [mailchimpMessage, setMailchimpMessage] = useState(null);

  const data = useStaticQuery(graphql`
    {
      prismicHoldingSite {
        data {
          newsletter_confirmation_text {
            html
          }
          newsletter_text {
            html
          }
        }
      }
    }
  `);

  // Form Setup
  const {
    control,
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    formState,
    getValues,
    reset,
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeForm({
        "form-name": `object-enquiry`,
        subject: `Object Enquiry – ${title}`,
        ...data,
      }),
    })
      .then(() => {
        setIsFormVisible(false);
        setFormMessage(
          "Thank you for submitting your enquiry. We'll be in touch soon."
        );
        reset();
      })
      .catch((error) => {
        console.log(error);
        setIsFormVisible(false);
        setFormMessage(
          "An error occurred while submitting your enquiry. Please email us directly at: info@metamorphosesobjects.com"
        );
      });
  };

  useEffect(() => {
    if (isFormVisible === false) {
      const closeForm = setTimeout(() => {
        setIsFormVisible(false);
        const fadeInForm = setTimeout(() => {
          setIsFormVisible(true);
        }, 750);
        return () => clearTimeout(fadeInForm);
      }, 8000);
      return () => clearTimeout(closeForm);
    }
  }, [isFormVisible]);

  return (
    <EnquiryFormContainer className="z-index-100">
      {isFormVisible && (
        <>
          <form
            name="object-enquiry"
            id="object-enquiry"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="object-enquiry" />
            <input type="hidden" name="object" value={title} ref={register()} />

            <div className="field">
              <label htmlFor="firstName">First name</label>
              <input
                id="fName"
                name="firstName"
                type="text"
                autoComplete="given-name"
                aria-required="true"
                aria-label="First Name"
                placeholder={errors.firstName ? `This field is required` : `…`}
                ref={register({
                  required: true,
                })}
              />
            </div>

            <div className="field">
              <label htmlFor="lastName">Last name</label>
              <input
                id="lName"
                name="lastName"
                type="text"
                autoComplete="family-name"
                aria-required="true"
                aria-label="Last Name"
                placeholder={errors.lastName ? `This field is required` : `…`}
                ref={register({
                  required: true,
                })}
              />
            </div>

            <div className="field">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                aria-required="true"
                aria-label="E-mail"
                placeholder={errors.email ? `Enter a valid email address` : `…`}
                ref={register({
                  required: true,
                })}
              />
            </div>

            <div className="field">
              <OptionsContainer>
                <label htmlFor="country" id="downshift-0-label">
                  Country
                </label>

                <Controller
                  control={control}
                  name="country"
                  defaultValue="United Kingdom"
                  render={({ onChange, onBlur, value }) => (
                    <CountrySelector onChange={onChange} selected={value} />
                  )}
                />
              </OptionsContainer>
            </div>

            <div className="field">
              <label htmlFor="message">message</label>
              <textarea
                name="message"
                rows="2"
                ref={register({
                  required: true,
                })}
              />
            </div>

            <SubmitButtonContainer>
              <SignupArrow />
              <button type="submit" className="submit-button" tabIndex="0">
                send
              </button>
            </SubmitButtonContainer>

            <ErrorsContainer>
              {(errors.fName || errors.lName || errors.email) && (
                <p>Please complete the missing information</p>
              )}
            </ErrorsContainer>
          </form>
        </>
      )}

      {!isFormVisible && (
        <div className="thank-you-message" id="thank_you_message">
          {formMessage}
          {/* <div
            dangerouslySetInnerHTML={{
              __html:
                data.prismicHoldingSite.data.newsletter_confirmation_text.html,
            }}
          /> */}
        </div>
      )}
    </EnquiryFormContainer>
  );
};
