import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const OpenButton = styled.button`
  position: relative;
  cursor: pointer;
  line-height: 10px;
  height: 18px;

  & svg {
    width: 18px;
    height: 18px;
  }

  // @media (max-width: 600px) {
  //   line-height: 1;

  //   & svg {
  //     width: 14px;
  //     height: auto;
  //   }
  // }
`;

export const OpenMenuToggle = ({ opacity, onClick, rotate }) => (
  <OpenButton onClick={onClick} opacity={opacity} rotate={rotate}>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00059 0L7.00059 14M14 7.00003L0 7.00003"
        stroke="black"
        strokeWidth="0.6"
      />
    </svg>
  </OpenButton>
);

const CloseButton = styled.button`
  position: relative;
  cursor: pointer;
  line-height: 10px;
  height: 18px;

  & svg {
    width: 18px;
    height: 18px;
    transform: rotate(45deg);
  }

  // @media (max-width: 600px) {
  //   line-height: 1;

  //   & svg {
  //     width: 14px;
  //     height: auto;
  //   }
  // }
`;

export const CloseMenuToggle = ({ opacity, onClick }) => (
  <CloseButton
    onClick={onClick}
    opacity={opacity}
    className="close-menu-toggle"
  >
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <path
        d="M2.0004 2L11.8999 11.8995M11.8995 2.00044L2 11.8999"
        stroke="black"
        strokeWidth="0.75"
      /> */}

      <path
        d="M7.00059 0L7.00059 14M14 7.00003L0 7.00003"
        stroke="black"
        strokeWidth="0.6"
      />
    </svg>
  </CloseButton>
);

const CloseToggleButton = styled.button`
  position: absolute;
  top: 23px;
  left: 23px;

  cursor: pointer;

  line-height: 10px;
  z-index: 200;

  & svg {
    width: 18px;
    height: 18px;
    transform: rotate(45deg);
  }

  @media (max-width: 960px) {
    position: relative;
    top: 0;
    left: 0;
  }
`;

export const CloseToggle = ({ onClick }) => (
  <CloseToggleButton onClick={() => onClick()}>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <path
        d="M2.0004 2L11.8999 11.8995M11.8995 2.00044L2 11.8999"
        stroke="black"
        strokeWidth="0.75"
      /> */}

      <path
        d="M7.00059 0L7.00059 14M14 7.00003L0 7.00003"
        stroke="black"
        strokeWidth="0.6"
      />
    </svg>
  </CloseToggleButton>
);
