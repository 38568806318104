import React, { useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

// Components
import { PopupContainer } from "./popup-container";

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const List = styled.ol`
  & li {
    width: 100%;
    margin: 0 0 30px 0;

    @media (max-width: 767px) {
      margin: 0 0 15px 0;
    }

    color: rgba(0, 0, 0, 0.5);

    &.active {
      color: #000;
    }

    &:hover {
      color: #000;
    }

    & p,
    & h2 {
      margin: 0;
      text-transform: lowercase;
    }

    & .container {
      max-width: 345px;
    }

    & .date {
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;

      text-transform: lowercase;

      @media (max-width: 960px) {
        line-height: 20px;
      }

      @media (max-width: 600px) {
        font-size: 11px;
      }
    }

    & .title {
      font-size: 26px;
      line-height: 30px;
      letter-spacing: 0.07em;

      @media (max-width: 960px) {
        font-size: 20px;
      }

      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
`;

const ImageContainer = styled.div`
  max-width: 210px;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Filters = styled.div`
  position: relative;
  width: 100%;
  padding: 0 0 35px 0;

  @media (max-width: 600px) {
    padding: 0 0 15px 0;
  }

  & .filter-container {
    grid-column: 1/7;

    & .filters-right {
      display: flex;
      flex-direciton: row;
      flex-wrap: wrap;

      & .clear-filter-button {
        margin: 0 30px 0 0;
      }
    }
  }

  & .container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    & button {
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.03em;

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 11px;
      }

      margin: 0;

      &.button-right {
        margin: 0 0 0 auto;

        @media (max-width: 960px) {
          margin: 0;
        }
      }

      &.with-marker {
        & svg {
          margin: 0 7px -2px 0;
        }
      }
    }
  }

  & .filter-buttons-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;

    z-index: 100;
    margin: 1em 0 0 -6px;

    @media (max-width: 600px) {
      margin: 1em 0 0 -3px;
    }

    & .top-line {
      margin: 0 0 30px 0;
    }

    & .grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 20px;
      align-items: flex-start;

      grid-column: 1/7;
    }

    & button {
      text-align: left;

      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.03em;

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 16px;
      }

      display: block;

      & span {
        opacity: 0;
      }

      &.active {
        & span {
          opacity: 1;
        }
      }

      &:hover {
        & span {
          opacity: 1;
        }
      }
    }

    & .filter-options,
    & .close-button {
      grid-column: 3/7;
    }

    & .filter-options {
      & button {
        text-transform: lowercase;
      }
    }

    & .inner-filters-container {
      grid-column: 3/7;
      min-height: 200px;

      & button {
        display: block;
      }
    }
  }
`;

const Text = styled.div`
  margin: 0 0 100px 0;

  @media (max-width: 600px) {
    margin: 0 0 50px 0;
  }

  & .text {
    font-size: 19px;
    line-height: 29px;
    letter-spacing: 0.02em;

    max-width: 580px;

    & p:first-of-type {
      margin-top: 0;
    }

    & p:last-of-type {
      margin-bottom: 0;
    }

    & a {
      &::before {
        content: "(";
      }
      &::after {
        content: ")";
      }
    }

    @media (max-width: 1300px) {
      font-size: 16px;
      line-height: 22px;
    }

    @media (max-width: 960px) {
      font-size: 14px;
      line-height: 19px;

      @media (max-width: 600px) {
        font-size: 11px;
        line-height: 16px;
      }
    }
  }
`;

export const Collaborations = ({
  setActivePopup,
  activePopup,
  location,
  quantity,
  popupOpacity,
}) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const [activeYear, setActiveYear] = useState("All");
  const [activeCategory, setActiveCategory] = useState("All");
  const [activeMaker, setActiveMaker] = useState("All");

  const [openFilter, setOpenFilter] = useState(null);

  const data = useStaticQuery(graphql`
    {
      prismicCollaborations {
        data {
          text {
            html
          }
          collaborations {
            collaboration {
              document {
                ... on PrismicCollaboration {
                  id
                  url
                  data {
                    category
                    year
                    title {
                      text
                    }
                    maker {
                      document {
                        ... on PrismicMaker {
                          id
                          data {
                            name {
                              text
                            }
                          }
                        }
                      }
                    }
                    thumbnail {
                      alt
                      fluid {
                        src
                        srcSet
                      }
                    }
                  }
                }
              }
              url
            }
          }
        }
      }
    }
  `);

  const content = data.prismicCollaborations.data.collaborations
    .filter((collaboration) => collaboration.collaboration.document !== null)
    .filter((collaboration) => {
      if (activeYear === "All") {
        return collaboration;
      } else {
        return collaboration.collaboration.document.data.year === activeYear;
      }
    })
    .filter((collaboration) => {
      if (activeCategory === "All") {
        return collaboration;
      } else {
        if (
          collaboration.collaboration.document.data.category.toLowerCase() ===
          activeCategory
        ) {
          return collaboration;
        }
      }
    })
    .filter((collaboration) => {
      if (activeMaker === "All") {
        return collaboration;
      } else {
        if (
          collaboration.collaboration.document.data.maker.document.data.name.text.toLowerCase() ===
          activeMaker
        ) {
          return collaboration;
        }
      }
    })
    .map((collaboration, index) => (
      <li
        key={`collaboration_id_${collaboration.collaboration.document.id}`}
        onMouseEnter={() => setActiveIndex(index)}
        className={activeIndex === index ? `active` : ``}
      >
        <Link to={collaboration.collaboration.document.url}>
          <div className="container">
            <p className="date">
              {collaboration.collaboration.document.data.maker.document !==
                null &&
                collaboration.collaboration.document.data.maker.document.data
                  .name.text}
            </p>
            <h2 className="title bl-arctic-regular">
              {collaboration.collaboration.document.data.title.text}
            </h2>
          </div>
        </Link>
      </li>
    ));

  const imageThumbnails = data.prismicCollaborations.data.collaborations
    .filter((collaboration) => collaboration.collaboration.document !== null)
    .map(
      (collaboration) => collaboration.collaboration.document.data.thumbnail
    );

  const allMakers = Array.from(
    new Set(
      data.prismicCollaborations.data.collaborations
        .filter(
          (collaboration) => collaboration.collaboration.document !== null
        )
        .filter(
          (collaboration) =>
            collaboration.collaboration.document.data.maker.document !== null
        )
        .map((collaboration) =>
          collaboration.collaboration.document.data.maker.document.data.name.text.toLowerCase()
        )
        .flat()
        .sort()
    )
  ).map((maker) => (
    <button
      onClick={() => setActiveMaker(maker)}
      key={`single_filter_${maker}`}
      className={activeMaker === maker ? `active filter` : `filter`}
    >
      <span>(</span>
      {maker}
      <span>)</span>
    </button>
  ));

  const allYears = Array.from(
    new Set(
      data.prismicCollaborations.data.collaborations
        .filter(
          (collaboration) => collaboration.collaboration.document !== null
        )
        .filter(
          (collaboration) =>
            collaboration.collaboration.document.data.year !== null
        )
        .map((collaboration) => collaboration.collaboration.document.data.year)
        .flat()
        .sort((a, b) => b - a)
    )
  ).map((year) => (
    <button
      onClick={() => setActiveYear(year)}
      key={`single_filter_${year}`}
      className={activeYear === year ? `active filter` : `filter`}
    >
      <span>(</span>
      {year}
      <span>)</span>
    </button>
  ));

  const allCategories = Array.from(
    new Set(
      data.prismicCollaborations.data.collaborations
        .filter(
          (collaboration) => collaboration.collaboration.document !== null
        )
        .filter(
          (collaboration) =>
            collaboration.collaboration.document.data.category !== null
        )
        .map((collaboration) =>
          collaboration.collaboration.document.data.category.toLowerCase()
        )
        .flat()
        .sort()
    )
  ).map((category) => (
    <button
      onClick={() => setActiveCategory(category)}
      key={`single_filter_${category}`}
      className={activeCategory === category ? `active filter` : `filter`}
    >
      <span>(</span>
      {category}
      <span>)</span>
    </button>
  ));

  return (
    <PopupContainer
      background={`#e9e8dd`}
      setActivePopup={setActivePopup}
      activePopup={activePopup}
      popupOpacity={popupOpacity}
    >
      <div className="popup-inner-container">
        <Filters>
          <div className="filter-container">
            <div className="container">
              {isFiltersOpen && (
                <button
                  onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                  className="button-left"
                >
                  filter +
                </button>
              )}

              <div className="filters-right">
                {isFiltersOpen && (
                  <button
                    onClick={() => {
                      setIsFiltersOpen(false);
                      setActiveMaker("All");
                      setActiveCategory("All");
                    }}
                    className="clear-filter-button"
                  >
                    (clear filter)
                  </button>
                )}
                <button
                  onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                  className="button-right"
                >
                  {isFiltersOpen ? `(close)` : `filter +`}
                </button>
              </div>
            </div>

            {isFiltersOpen && (
              <div className="filter-buttons-container">
                <div className="grid bottom-line">
                  <div>
                    <button
                      onClick={() => {
                        setActiveYear("All");
                        setActiveCategory("All");
                        setActiveMaker("All");

                        if (openFilter === "year") {
                          setOpenFilter(null);
                        } else {
                          setOpenFilter("year");
                        }
                      }}
                      className={
                        openFilter === "year" ? `active filter` : `filter`
                      }
                    >
                      <span>(</span>year<span>)</span>
                    </button>

                    <button
                      onClick={() => {
                        setActiveYear("All");
                        setActiveCategory("All");
                        setActiveMaker("All");

                        if (openFilter === "category") {
                          setOpenFilter(null);
                        } else {
                          setOpenFilter("category");
                        }
                      }}
                      className={
                        openFilter === "category" ? `active filter` : `filter`
                      }
                    >
                      <span>(</span>category<span>)</span>
                    </button>

                    <button
                      onClick={() => {
                        setActiveYear("All");
                        setActiveCategory("All");
                        setActiveMaker("All");

                        if (openFilter === "maker") {
                          setOpenFilter(null);
                        } else {
                          setOpenFilter("maker");
                        }
                      }}
                      className={
                        openFilter === "maker" ? `active filter` : `filter`
                      }
                    >
                      <span>(</span>maker<span>)</span>
                    </button>
                  </div>

                  <div className="filter-options">
                    {openFilter === "year" && (
                      <div className="inner-filters-container">{allYears}</div>
                    )}

                    {openFilter === "category" && (
                      <div className="inner-filters-container">
                        {allCategories}
                      </div>
                    )}

                    {openFilter === "maker" && (
                      <div className="inner-filters-container">{allMakers}</div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </Filters>

        <Text>
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: data.prismicCollaborations.data.text.html,
            }}
          />
        </Text>

        <Flex>
          <List>{content}</List>

          <ImageContainer>
            {imageThumbnails[activeIndex].fluid !== null && (
              <img
                srcSet={imageThumbnails[activeIndex].fluid.srcSet}
                src={imageThumbnails[activeIndex].fluid.src}
                alt={imageThumbnails[activeIndex].alt}
              />
            )}
          </ImageContainer>
        </Flex>
      </div>
    </PopupContainer>
  );
};
