import React, { useState, useEffect, useContext } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useWindowScroll } from "react-use";

// Context
import { ConversationsMenuColor } from "../context/conversation-menu-color";

const FooterWrapper = styled.footer`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  align-items: flex-start;

  padding: 20px 0;

  line-height: 20px;

  transform: translateY(60px);
  transition: 250ms transform ease;

  &.sticky {
    position: sticky;
    bottom: 0;
    background-color: ${(props) => props.bgColor};
    margin: 0 -20px;
    transform: translateY(0);
  }

  & ul {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    width: 100%;

    padding: 0;
    margin: 0;

    grid-column: 5 / 13;

    @media (max-width: 1030px) {
      grid-column: 4 / 13;
    }

    // @media (max-width: 1190px) {
    //   grid-column: 1 / 13;
    // }
  }

  & ul,
  & li {
    list-style: none;
  }

  & li {
    text-transform: lowercase;

    margin: 0 30px 0 0;

    @media (max-width: 1535px) {
      margin: 0 15px 0 0;
    }

    &:last-of-type {
      margin: 0 20px 0 0;
    }

    & button,
    & a {
      & span {
        opacity: 0;
      }

      &.current {
        & span {
          opacity: 1;
        }
      }
    }

    &:hover {
      & span {
        opacity: 1;
      }
    }
  }

  @media (max-width: 960px) {
    display: none;
  }
`;

export const Footer = ({ setActivePopup, hasScrolledToBottom, location }) => {
  const { x, y } = useWindowScroll();

  const [showFooter, setShowFooter] = useState(false);
  const [menuColor, setMenuColor] = useContext(ConversationsMenuColor);

  useEffect(() => {
    if (showFooter === false) {
      setShowFooter(true);
    }
  }, [y]);

  useEffect(() => {
    setShowFooter(false);
  }, [location]);

  return (
    <>
      <FooterWrapper
        className={hasScrolledToBottom || showFooter ? `sticky` : ``}
        bgColor={location.includes(`/conversations`) ? menuColor : `#fafaf5`}
      >
        <ul>
          <li className="small-text">
            <Link to={`/about/`} activeClassName="current">
              <span>(</span>contact<span>)</span>
            </Link>
          </li>
          <li className="small-text">
            <button onClick={() => setActivePopup(`newsletter`)}>
              <span>(</span>newsletter<span>)</span>
            </button>
          </li>
          <li className="small-text">
            <button onClick={() => setActivePopup(`press`)}>
              <span>(</span>press<span>)</span>
            </button>
          </li>
          <li className="small-text">
            <Link to={`/privacy-policy/`} activeClassName="current">
              <span>(</span>privacy policy<span>)</span>
            </Link>
          </li>
          <li className="small-text">
            <Link to={`/shipping-and-returns/`} activeClassName="current">
              <span>(</span>shipping + returns<span>)</span>
            </Link>
          </li>
          <li className="small-text">
            <Link to={`/terms-and-conditions/`} activeClassName="current">
              <span>(</span>terms + conditions<span>)</span>
            </Link>
          </li>
        </ul>
      </FooterWrapper>
    </>
  );
};
