import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

// Components
import { PopupContainer } from "./popup-container";
import { AspectRatioImageContainer } from "../containers/aspect-ratio-image-container";

// Utils
import { FormatProductTitle } from "../utils/format-product-title";

const Product = styled.div`
  margin: 0 0 115px 0;

  @media (max-width: 600px) {
    margin: 0 0 25px 0;
  }

  & .aspect-ratio-image-container {
    mix-blend-mode: multiply;

    transition: 250ms opacity ease;

    & img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  & .container {
    display: grid;
    grid-template-columns: 1fr auto;

    margin: 10px 0 0 0;

    & p,
    & h2 {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: 0.03em;
      text-transform: lowercase;

      margin: 0;

      @media (max-width: 600px) {
        font-size: 9px;
        line-height: 12px;
      }
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 80px;

  @media (max-width: 960px) {
    grid-column-gap: 25px;
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
  }

  & .object {
  }
`;

export const Archive = ({ setActivePopup }) => {
  const data = useStaticQuery(graphql`
    {
      prismicObjects {
        data {
          archive_objects {
            object {
              document {
                ... on PrismicObject {
                  id
                  url
                  data {
                    shopify_product
                    maker {
                      document {
                        ... on PrismicMaker {
                          id
                          data {
                            name {
                              text
                            }
                          }
                        }
                      }
                    }
                    year
                    thumbnail {
                      alt
                      fluid(imgixParams: { sat: -100 }) {
                        src
                        srcSet
                      }
                      dimensions {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const SingleImage = ({ thumbnail }) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
      const closeForm = setTimeout(() => {
        setOpacity(1);
      }, 1000);
      return () => clearTimeout(closeForm);
    }, [imageLoaded]);

    return (
      <div
        className={`aspect-ratio-image-container`}
        style={{ opacity: opacity }}
      >
        <AspectRatioImageContainer image={null} padding={125}>
          <img
            onLoad={() => setImageLoaded(true)}
            className={`thumbnail`}
            srcSet={thumbnail.fluid.srcSet}
            src={thumbnail.fluid.src}
            alt={thumbnail.alt}
            loading="lazy"
          />
        </AspectRatioImageContainer>
      </div>
    );
  };

  const content = data.prismicObjects.data.archive_objects
    .filter((object) => object.object.document !== null)
    .filter((object) => object.object.document.data.shopify_product !== null)
    .map((object, index) => (
      <Product
        key={`single_product_${index}_${object.object.id}`}
        className={`object`}
      >
        <Link to={`${object.object.document.url}`}>
          {object.object.document.data.thumbnail.fluid !== null && (
            <SingleImage thumbnail={object.object.document.data.thumbnail} />
          )}

          <div className="container">
            <div className="left">
              <p className="maker">
                {
                  FormatProductTitle(
                    object.object.document.data.shopify_product.title
                  )[0]
                }
              </p>
              <h2 className="title">
                {
                  FormatProductTitle(
                    object.object.document.data.shopify_product.title
                  )[1]
                }
              </h2>
            </div>

            <div className="right">
              <p className="year">{object.object.document.data.year}</p>
            </div>
          </div>
        </Link>
      </Product>
    ));

  return (
    <PopupContainer
      background={`#A2AFA0`}
      setActivePopup={setActivePopup}
      activePopup={`archive`}
    >
      <div className="popup-inner-container">
        <Grid>{content}</Grid>
      </div>
    </PopupContainer>
  );
};
