import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

.resize-animation-stopper * {
  transition: none !important;
}

html,
body {
	overscroll-behavior: none;
}

html,
html.wf-loading {
  body {
    opacity: 0;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}

*:focus{
  outline: 0;
}

html {
  background-color: #FAFAF5;
}

body {
  font-family: "Styrene B Web", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 19px;
  line-height: 29px;
  letter-spacing: 0.02em;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  @media (max-width: 960px) {
    font-size: 14px;
    line-height: 19px;
  }

  @media (max-width: 600px) {
    font-size: 11px;
    line-height: 16px;
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "Styrene B Web", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;
}


/* Title 1 */
h1{
  font-size: 50px;
  line-height: 50px;
  letter-spacing: 0.3px;
}

/* Title 2 */
h2 {
  font-size: 26px;
  line-height: 30px;
  letter-spacing: 0.07em;
}

/* Heading 1 */
h3 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

/* Heading 2 */
h4 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

/* Heading 3 */
h5 {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;
}

.bl-arctic-regular,
.bl-arctic-regular > h1,
.bl-arctic-regular > h2,
.bl-arctic-regular > h3,
.bl-arctic-regular > h4 {
  font-family: "bl-arctic-regular";
}

.small-text {
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;

  @media (max-width: 600px) {
    font-size: 9px;
    line-height: 12px;
  }
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    -webkit-appearance:none;
    border:0;
    margin:0;
    padding:0;

    cursor:pointer;
    color: #000;

    &:focus,
    &:hover{
      outline: none;
    }
  }




/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ol, ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}


	/*--------------------------------------------------------------
  ## General modals
	--------------------------------------------------------------*/
	.ReactModalPortal,
	.ReactModal__Overlay--after-open {
		z-index: 100000;
	}

	.ReactModal__Body--open {
		overflow-y: hidden;
	}

	.product-enquiry-modal,
	.delivery-modal {
		position: fixed;

		max-width: 720px;
		width: calc(100% - 40px);

		padding: 20px;

		top: 50%;
		left: 50%;
		right: auto;
		bottom: auto;

		transform: translate(-50%, -50%);

		background-color: #fff;

		overflow-y: auto;

		@media (max-width: 600px) {
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

      transform: none;

      width: auto;
		}
	}




/*--------------------------------------------------------------
  ## Input Placeholders
  --------------------------------------------------------------*/

// ::-webkit-input-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 600px) {
//       font-size: 19px !important;
//     }

// }
// ::-moz-placeholder{
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 600px) {
//       font-size: 19px !important;
//     }

// }
// :-ms-input-placeholder{
//       color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 600px) {
//       font-size: 19px !important;
//     }

// }
// :-moz-placeholder {
//     color: #000 !important;
//     font-family: 'MonumentGrotesk-Medium', 'Helvetica Neue', 'Lucida Grande',
//       sans-serif !important;
//     font-size: 24px !important;

//     @media (max-width: 600px) {
//       font-size: 19px !important;
//     }

//   }
`;

export default GlobalStyle;
