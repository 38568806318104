import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useClickAway } from "react-use";

// Components
import { DetermineActivePopup } from "./determine-active-popup";
import SearchIndex from "../search/search-index";

// Icons
import { Logo } from "../icons/logo";
import { CloseMenuToggle } from "../icons/toggle";
import { BasketEmpty, BasketFull } from "../icons/basket";

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.bgColor};

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 20px;
  align-items: flex-start;

  z-index: 200;

  & .logo-link {
    z-index: 20;

    grid-column: span 6;
    padding: 20px;

    @media (max-width: 1400px) {
      grid-column: span 5;
    }
    @media (max-width: 1140px) {
      grid-column: span 4;
    }
  }

  & nav.main-navigation {
    grid-column: span 6;
    padding: 20px 20px 20px 0;

    z-index: 100;
    visibility: ${(props) => props.visibility};

    @media (max-width: 1400px) {
      grid-column: span 7;
    }

    @media (max-width: 1140px) {
      grid-column: span 8;
    }

    & ol {
      display: flex;
      flex-direction: row;

      /* @media (max-width: 1140px) {
        justify-content: flex-end;
      } */

      padding: 0;
      margin: 0;

      & li {
        margin: 0 30px 0 0;

        @media (max-width: 1400px) {
          margin: 0 15px 0 0;
        }

        &:first-of-type {
          margin: 0 30px 0 -7px;
        }

        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.03em;

        &.basket {
          margin: 0 0 0 auto;

          @media (max-width: 1050px) {
            margin: 0;
          }
        }

        &.search {
          display: flex;
          margin: 0 0 0 4px;

          & .is-closed {
            margin: 2px 0 0 0;
          }

          & .close-menu-toggle {
            margin: 0 0 0 10px;

            & svg {
              width: 23px;
              height: auto;
            }
          }
        }

        & button {
          line-height: 20px;
        }

        & button,
        & a {
          & span {
            opacity: 0;
          }

          &.current {
            & span {
              opacity: 1;
            }
          }
        }

        &:hover {
          & span {
            opacity: 1;
          }
        }
      }
    }

    &.search-open {
      & li:not(.search) {
        display: none;
      }

      & .search {
        margin: 0 0 0 auto;

        width: 100%;
        max-width: 400px;
      }
    }
  }
`;

export const DesktopMenu = ({
  location,
  quantity,
  activePopup,
  setActivePopup,
  isLogoVisible,
  isHeaderVisible,
  delay,
  transition,
  setShowNewsletter,
  menuColor,
}) => {
  const [isSearchOpen, setSearchOpen] = useState(false);
  const ref = useRef(null);
  useClickAway(ref, () => {
    setSearchOpen(false);
  });

  return (
    <HeaderWrapper
      ref={ref}
      visibility={isHeaderVisible ? `visible` : `hidden`}
      bgColor={
        isHeaderVisible
          ? location.includes(`/conversations`)
            ? menuColor
            : `#fafaf5`
          : `transparent`
      }
    >
      <div className="logo-link">
        <Link to={"/"}>
          <Logo
            isLogoVisible={isLogoVisible}
            delay={delay}
            transition={transition}
          />
        </Link>
      </div>

      {isHeaderVisible && (
        <nav
          className={`main-navigation ${
            isSearchOpen ? `search-open` : `search-closed`
          }`}
        >
          <ol>
            <li>
              <Link
                to="/"
                activeClassName={activePopup === null ? "current" : ""}
                className={
                  (location.includes(`/objects/`) && activePopup === null) ||
                  (location.includes(`/objects/`) &&
                    activePopup === "product-enquiry") ||
                  (location.includes(`/objects/`) &&
                    activePopup === "product-process") ||
                  (location.includes(`/objects/`) &&
                    activePopup === "added-to-bag")
                    ? "current"
                    : ""
                }
              >
                <span>(</span>objects<span>)</span>
              </Link>
            </li>
            <li>
              <button
                onClick={() => setActivePopup("biographies")}
                className={activePopup === "biographies" ? "current" : ""}
              >
                <span>(</span>biographies<span>)</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => setActivePopup("collaborations")}
                className={
                  activePopup === "collaborations" ||
                  (location.includes(`/collaborations/`) &&
                    activePopup === null)
                    ? "current"
                    : ""
                }
              >
                <span>(</span>collaborations<span>)</span>
              </button>
            </li>
            <li>
              <button
                onClick={() => setActivePopup("conversations")}
                className={
                  activePopup === "conversations" ||
                  (location.includes(`/conversations`) && activePopup === null)
                    ? "current"
                    : ""
                }
              >
                <span>(</span>conversations<span>)</span>
              </button>
            </li>
            <li>
              <Link
                to="/about/"
                activeClassName={activePopup === null ? "current" : ""}
              >
                <span>(</span>about<span>)</span>
              </Link>
            </li>

            <li className="basket">
              <Link to="/bag/">
                {quantity > 0 ? <BasketFull /> : <BasketEmpty />}
              </Link>
            </li>

            <li className="search">
              <SearchIndex
                location={location}
                type={"header"}
                isSearchOpen={isSearchOpen}
                setSearchOpen={setSearchOpen}
              />

              {isSearchOpen && (
                <CloseMenuToggle onClick={() => setSearchOpen(false)} />
              )}
            </li>
          </ol>
        </nav>
      )}

      {activePopup !== null && (
        <DetermineActivePopup
          activePopup={activePopup}
          setActivePopup={setActivePopup}
          location={location}
          quantity={quantity}
          setShowNewsletter={setShowNewsletter}
        />
      )}
    </HeaderWrapper>
  );
};
