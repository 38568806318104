import React from "react";
import styled from "styled-components";

const Icon = styled.svg``;

export const SignupArrow = () => (
  <Icon
    width="101"
    height="13"
    viewBox="0 0 101 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M93.7344 12.3585L99.4561 6.731L93.7344 1.10352"
      stroke="black"
      strokeWidth="0.3"
    />
    <path
      d="M93.8425 12.468L93.6289 12.255L99.2408 6.73252L93.6289 1.21309L93.8425 1L99.6741 6.73252L93.8425 12.468Z"
      stroke="black"
      strokeWidth="0.3"
    />

    <path
      d="M99.1077 7H1V7.30012H99.1077V7Z"
      stroke="black"
      strokeWidth="0.3"
    />
  </Icon>
);
